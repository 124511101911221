import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import PressableIcon from '../../components/PressableIcon'
import useScreen from '../../components/screen/hooks/useScreen'
import { useData } from '../../contexts/DataContext'
import { useSettings } from '../../contexts/SettingsContext'
import { usePackages } from '../../hooks/usePackages'
import { ThemeColorExpanded } from '../../types'
import BaseHeader from '../BaseHeader'

type PackagesHeaderProps = { onSaving: (busy: boolean) => void }

function PackagesHeader({ onSaving }: PackagesHeaderProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { packages, customers } = useData()
  const { deletePackage } = usePackages()
  const { toggleDetailOpen } = useScreen()
  const [busy, setBusy] = useState(false)
  const { generalSettings } = useSettings()

  const onSendToCloud = () => {
    savePackages()
      .catch(error => {
        console.error('savePackages error', error)
      })
      .finally(() => {
        setBusy(false)
      })
  }

  useEffect(() => {
    onSaving(busy)
  }, [busy, onSaving])

  const savePackages = async () => {
    for (const pkg of packages) {
      const isPackageClosed = generalSettings.syncOnlyFinished ? pkg.closed : true
      if (isPackageClosed) {
        await api
          .postPackages({
            organizationId: customers[0].organizationId,
            packageName: pkg.name,
            addDate: pkg.date,
            isClosed: pkg.closed,
            customerId: pkg.customerId,
            typologyId: pkg.typologyId,
            qualityId: pkg.qualityId,
            woodId: pkg.woodId,
            measurerId: pkg.measurerId,
            sawmillId: pkg.sawmillId,
            gradeId: pkg.gradeId,
            packageType: pkg.packageType.value,
            ums: pkg.ums?.value ?? 'CMCMMM',
            note: pkg.notes,
            packageBoards: pkg.boards,
          })
          .then(() => {
            deletePackage(pkg.tmpId)
          })
          .catch(error => {
            console.error('savePackages error', error)
          })
      }
    }
  }

  const rightComponent = (
    <>
      <IM.View style={styles.container}>
        <PressableIcon icon={['fal', 'filter']} size={21} color={theme.header.main.text.primary} onPress={toggleDetailOpen} />
        <PressableIcon icon={['fal', 'cloud-arrow-up']} size={24} color={theme.header.main.text.primary} onPress={onSendToCloud} />
      </IM.View>
    </>
  )

  return <BaseHeader title={i18n.t('PACKAGES')} rightComponent={rightComponent} />
}

export default PackagesHeader

const styles = StyleSheet.create({
  container: { flexDirection: 'row' },
})
