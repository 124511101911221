import { IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import Icon from './Icon'

type Props = {
  onDeletePress?: () => void
  onKeyPress: (key: string) => void
  onSubmitPress: () => void
}
const keys = ['7', '8', '9', 'DEL', '4', '5', '6', '<<', '1', '2', '3', '-', '0', '.'] as const
//TODO add theme colors - logic for <<
const CustomKeyboard = ({ onKeyPress, onDeletePress, onSubmitPress }: Props) => {
  const { i18n } = useLanguage()

  return (
    <View style={styles.keyboardContainer}>
      <View style={styles.keysContainer}>
        {keys.map(key => (
          <TouchableOpacity
            key={key}
            style={styles.key}
            onPress={() => {
              if (key === 'DEL' && onDeletePress) {
                onDeletePress()
              } else {
                onKeyPress(key)
              }
            }}>
            {key === 'DEL' ? <Icon icon={['fal', 'delete-left']} size={20} color={'white'} /> : <Text style={styles.keyText}>{key}</Text>}
          </TouchableOpacity>
        ))}
        <TouchableOpacity style={[styles.key, styles.okButton]} onPress={onSubmitPress}>
          <IM.Text style={styles.keyText}>{i18n.t('OK')}</IM.Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  keyboardContainer: {
    backgroundColor: '#333',
    padding: 10,
  },
  keysContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  key: {
    flexBasis: '23%',
    margin: '1%',
    paddingVertical: 20,
    backgroundColor: '#555',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 65,
  },
  okButton: {
    flexBasis: '48%',
    backgroundColor: '#777',
    height: 65,
  },
  keyText: {
    color: '#fff',
    fontSize: 18,
  },
})

export default CustomKeyboard
