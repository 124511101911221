import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Sawmill } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import SawmillCard from '../cards/SawmillCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Sawmill | undefined) => void
  required?: boolean
  selectedSawmillId?: number
}

export default function SawmillSelector({ onChange, selectedSawmillId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { sawmills, organizationId, setSawmills } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(sawmills, search, ['sawmillName', 'sawmillId', 'sawmillCode'])

  const render = ({ item }: SectionListRenderItemInfo<Sawmill, ListSection<Sawmill>>, onPress?: () => void) => {
    return <SawmillCard sawmill={item} onPress={onPress} selected={item.sawmillId === selectedSawmillId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Sawmill | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveSawmill(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveSawmill = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postSawmill({ sawmillName: inputValue, organizationId: organizationId })
      const res = await api.getSawmills({ organizationId: organizationId })
      setSawmills(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedSawmill = useMemo(() => sawmills?.find(elem => elem.sawmillId === selectedSawmillId), [sawmills, selectedSawmillId])

  return (
    <SelectInput
      id={'sawmillId'}
      data={filteredData}
      value={selectedSawmill}
      loading={false}
      refresh={undefined}
      title={i18n.t('SAWMILL')}
      screenTitle={i18n.t('SAWMILL')}
      noDataMessage={i18n.t('NO_SAWMILL')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.sawmillName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      {...props}
    />
  )
}
