import { apiClient } from '@infominds/react-api'

import './apiRequestTypes'
import './apiResponseTypes'

import { API_BASE_URL } from '../constants/url'
import { ConfiguredDevice } from '../types'
import { apiOptions } from './apiOptions'
import {
  DeleteCustomerRequest,
  DeleteGradeRequest,
  DeleteLicenseRequest,
  DeleteMeasurerRequest,
  DeleteOriginRequest,
  DeleteQualityRequest,
  DeleteSawmillRequest,
  DeleteTypologyRequest,
  DeleteUserRequest,
  DeleteWoodRequest,
  GetCurrentOrganizationRequest,
  GetCustomerRequest,
  GetCustomersRequest,
  GetGradeRequest,
  GetGradesRequest,
  GetLicenseRequest,
  GetLicensesRequest,
  GetMeasurerRequest,
  GetMeasurersRequest,
  GetOrganizationRequest,
  GetOriginRequest,
  GetOriginsRequest,
  GetQualitiesRequest,
  GetQualityRequest,
  GetSawmillRequest,
  GetSawmillsRequest,
  GetStandingUpRequest,
  GetTypologiesRequest,
  GetTypologyRequest,
  GetUserRequest,
  GetUsersRequest,
  GetWoodRequest,
  GetWoodsRequest,
  PostCustomerRequest,
  PostGradeRequest,
  PostLicenseRequest,
  PostLotRequest,
  PostMeasurerRequest,
  PostOriginRequest,
  PostPackageBoardsRequest,
  PostPackageRequest,
  PostQualityRequest,
  PostSawmillRequest,
  PostTypologyRequest,
  PostUserRequest,
  PostWoodRequest,
  PutActivateRequest,
  PutLicenseRequest,
  PutUserRequest,
} from './apiRequestTypes'
import {
  ActivateResponse,
  Customer,
  Grade,
  Measurer,
  Organization,
  OrganizationLicense,
  Origin,
  Quality,
  Sawmill,
  SessionLoginResponse,
  StandingUp,
  Typology,
  User,
  Wood,
} from './apiResponseTypes'

export type Locale = 'en' | 'de' | 'it'

function reAuthenticate(): () => Promise<string | undefined> {
  return async () => {
    return await new Promise<string | undefined>((resolve, reject) => {
      resolve(undefined)
    })
  }
}

export const client = apiClient(apiOptions, '/api/', { reAuthentication: reAuthenticate() }, false, true, true)

const api = {
  // LOGIN
  postSession: async (licenseKey: string) => {
    const response = await fetch(`${API_BASE_URL}/api/Session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(licenseKey),
    })
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`)
    }

    const data = response.text()
    return data
  },
  postSessionLogin: async (username: string, password: string): Promise<SessionLoginResponse> => {
    const requestBody = {
      username: username,
      password: password,
    }
    const response = await fetch(`${API_BASE_URL}/api/Session/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`)
    }

    return response.json() as Promise<SessionLoginResponse>
  },

  // PUT
  activate: (body: PutActivateRequest, abortController?: AbortController) =>
    client.PUT<ActivateResponse>('organizations/activate', body, abortController),
  putUser: (body: PutUserRequest, abortController?: AbortController) => client.PUT('user', body, abortController),
  putLicense: (body: PutLicenseRequest, abortController?: AbortController) => client.PUT('license', body, abortController),
  putOrganization: (body: Organization, abortController?: AbortController) => client.PUT('organizations', body, abortController),
  putCustomer: (body: Customer, abortController?: AbortController) => client.PUT('customers', body, abortController),
  putTypology: (body: Typology, abortController?: AbortController) => client.PUT('typologies', body, abortController),
  putWood: (body: Wood, abortController?: AbortController) => client.PUT('woods', body, abortController),
  putGrade: (body: Grade, abortController?: AbortController) => client.PUT('grades', body, abortController),
  putQuality: (body: Quality, abortController?: AbortController) => client.PUT('qualities', body, abortController),
  putOrigin: (body: Origin, abortController?: AbortController) => client.PUT('origins', body, abortController),
  putSawmill: (body: Sawmill, abortController?: AbortController) => client.PUT('sawmills', body, abortController),
  putMeasurer: (body: Measurer, abortController?: AbortController) => client.PUT('measurers', body, abortController),
  putStandingUp: (body: StandingUp, abortController?: AbortController) => client.PUT('trunks/standingup', body, abortController),

  // GET
  getUser: (req: GetUserRequest) => client.GET<User>('user/', req.userId.toString()),
  getUsers: (req: GetUsersRequest) => client.GET<User[]>('user/organization/', req.organizationId.toString()),
  getOrganizations: () => client.GET<Organization[]>('organizations'),
  getCustomers: (req: GetCustomersRequest) => client.GET<Customer[]>('customers', req),
  getCustomer: (req: GetCustomerRequest) => client.GET<Customer>('customers/', req.customerId.toString()),
  getTypologies: (req: GetTypologiesRequest) => client.GET<Typology[]>('typologies', req),
  getTypology: (req: GetTypologyRequest) => client.GET<Typology>('typologies/', req.typologyId.toString()),
  getWoods: (req: GetWoodsRequest) => client.GET<Wood[]>('woods', req),
  getWood: (req: GetWoodRequest) => client.GET<Wood>('woods/', req.woodId.toString()),
  getGrades: (req: GetGradesRequest) => client.GET<Grade[]>('grades', req),
  getGrade: (req: GetGradeRequest) => client.GET<Grade>('grades/', req.gradeId.toString()),
  getQualities: (req: GetQualitiesRequest) => client.GET<Quality[]>('qualities', req),
  getQuality: (req: GetQualityRequest) => client.GET<Quality>('qualities/', req.qualityId.toString()),
  getOrigins: (req: GetOriginsRequest) => client.GET<Origin[]>('origins', req),
  getOrigin: (req: GetOriginRequest) => client.GET<Origin>('origins/', req.originId.toString()),
  getSawmills: (req: GetSawmillsRequest) => client.GET<Sawmill[]>('sawmills', req),
  getSawmill: (req: GetSawmillRequest) => client.GET<Sawmill>('sawmills/', req.sawmillId.toString()),
  getMeasurers: (req: GetMeasurersRequest) => client.GET<Measurer[]>('measurers', req),
  getMeasurer: (req: GetMeasurerRequest) => client.GET<Measurer>('measurers/', req.measurerId.toString()),
  getConfiguredDevices: () => client.GET<ConfiguredDevice[]>('devices'),
  getStandingUp: (req: GetStandingUpRequest) => client.GET<StandingUp[]>('trunks/standingup/organization/', req),
  getLicenses: (req: GetLicensesRequest) => client.GET<OrganizationLicense[]>('license/organization/', req.organizationId.toString()),
  getCurrentOrganization: (req: GetCurrentOrganizationRequest) => client.GET<Organization>('organizations/', req.organizationId.toString()),
  getOrganization: (req: GetOrganizationRequest) => client.GET<Organization>('organizations/', req.organizationId.toString()),
  getLicense: (req: GetLicenseRequest) => client.GET<OrganizationLicense>('license/' + req.licenseId.toString()),

  // POST
  postPackages: (body: PostPackageRequest, abortController?: AbortController) => client.POST('packages', body, abortController),
  postPackagesBoards: (body: PostPackageBoardsRequest, abortController?: AbortController) =>
    client.POST('packageboards/create', body, abortController),
  postLots: (body: PostLotRequest, abortController?: AbortController) => client.POST('lots', body, abortController),
  postCustomer: (body: PostCustomerRequest, abortController?: AbortController) => client.POST('customers', body, abortController),
  postTypology: (body: PostTypologyRequest, abortController?: AbortController) => client.POST('typologies', body, abortController),
  postWood: (body: PostWoodRequest, abortController?: AbortController) => client.POST('woods', body, abortController),
  postGrade: (body: PostGradeRequest, abortController?: AbortController) => client.POST('grades', body, abortController),
  postQuality: (body: PostQualityRequest, abortController?: AbortController) => client.POST('qualities', body, abortController),
  postOrigin: (body: PostOriginRequest, abortController?: AbortController) => client.POST('origins', body, abortController),
  postSawmill: (body: PostSawmillRequest, abortController?: AbortController) => client.POST('sawmills', body, abortController),
  postMeasurer: (body: PostMeasurerRequest, abortController?: AbortController) => client.POST('measurers', body, abortController),
  postUser: (body: PostUserRequest, abortController?: AbortController) => client.POST<User>('user', body, abortController),
  postLicense: (body: PostLicenseRequest, abortController?: AbortController) => client.POST<OrganizationLicense>('license', body, abortController),
  postOrganization: (body: Organization, abortController?: AbortController) => client.POST<Organization>('organizations', body, abortController),

  // DELETE
  deleteUser: (req: DeleteUserRequest, abortController?: AbortController) => client.DELETE('user/' + req.userId.toString(), abortController),
  deleteLicense: (req: DeleteLicenseRequest, abortController?: AbortController) =>
    client.DELETE('license/' + req.licenseId.toString(), abortController),
  deleteOrganization: (organizationId: number, abortController?: AbortController) =>
    client.DELETE('organizations/' + organizationId.toString(), abortController),
  deleteCustomer: (req: DeleteCustomerRequest, abortController?: AbortController) =>
    client.DELETE('customers/' + req.customerId.toString(), abortController),
  deleteTypology: (req: DeleteTypologyRequest, abortController?: AbortController) =>
    client.DELETE('typologies/' + req.typologyId.toString(), abortController),
  deleteWood: (req: DeleteWoodRequest, abortController?: AbortController) => client.DELETE('woods/' + req.woodId.toString(), abortController),
  deleteGrade: (req: DeleteGradeRequest, abortController?: AbortController) => client.DELETE('grades/' + req.gradeId.toString(), abortController),
  deleteQuality: (req: DeleteQualityRequest, abortController?: AbortController) =>
    client.DELETE('qualities/' + req.qualityId.toString(), abortController),
  deleteOrigin: (req: DeleteOriginRequest, abortController?: AbortController) => client.DELETE('origins/' + req.originId.toString(), abortController),
  deleteSawmill: (req: DeleteSawmillRequest, abortController?: AbortController) =>
    client.DELETE('sawmills/' + req.sawmillId.toString(), abortController),
  deleteMeasurer: (req: DeleteMeasurerRequest, abortController?: AbortController) =>
    client.DELETE('measurers/' + req.measurerId.toString(), abortController),
}

export default api
