import { Language, ModalController, ThemeColorsType } from '@infominds/react-native-components'
import { Peripheral } from 'react-native-ble-manager'

import { translation } from './assets/languages/i18next'

export type UploadStatus = 'waiting' | 'waitingWithoutPending' | 'uploading' | 'done' | 'mandatoryMissing'

export interface ThemeColorExpanded extends ThemeColorsType {
  backgroundSecondary: string
  button: ThemeButtonExtended
  card: ThemeCardExtended
  chip: ThemeChipExtended
  filterTag: { default: FilterTag; active: FilterTag; header: FilterTag }
  dropdown: { arrow: string }
  general: {
    info: string
    error: string
    warn: string
    word: string
    excel: string
    pdf: string
    image: string
    text: string
    blue: string
  }
  header: {
    main: {
      background: string
      error: string
      text: { primary: string; secondary: string; placeholder: string }
    }
    detail: {
      background: string
      skeletonBackground: string
    }
  }
  inputBox: {
    border: { active: string; disabled: string; error: string }
    background: { active: string; disabled: string }
  }
  loader: {
    background: string
    border: string
  }
  moreSheet: {
    background: string
    navigationElement: {
      background: string
      selected: string
    }
    indicator: string
  }
  pressable: ThemePressableExtended
  pressableIcon: {
    disabled: string
  }
  radioButton: string
  settings: {
    badge: {
      background: string
    }
  }
  drawerNavigator: {
    background: string
    bar: string
    hover: string
    icon: {
      focused: string
      unFocused: string
    }
  }
  table: {
    text: string
    icon: string
    header: {
      text: string
      background: string
      icon: {
        active: string
        inactive: string
      }
    }
    rowOdd: string
    rowEven: string
    divider: string
  }
}

interface ThemePressableExtended extends ThemePressable {
  highlight: string
  animatedHighlight: string
}

export type DividerType = 'left' | 'right'

interface ThemeChipExtended extends ThemeChip {
  border: string
  active: {
    border: string
    background: string
    text: string
  }
  icon: {
    enabled: string
    disabled: string
  }
  installation: string
  removal: string
}

export type SearchKey<T> = keyof T | [string, ...(keyof T)[]]

interface ThemeCardExtended extends ThemeCard {
  secondaryBackground: string
  cardBackground: {
    active: string
    selected: string
  }
  accent: {
    active: string
  }
  button: {
    active: string
    inactive: string
    icon: {
      active: string
      inactive: string
    }
  }
}

export interface TextInputRef {
  focus: () => void
  blur: () => void
}

export type Border = 'top' | 'bottom'

interface ThemeButtonExtended extends ThemeButton {
  background: string
  disabledBackground: string
  icon: string
  disabledIcon: string
  qualityCheck: string
}

export type ThemePressable = {
  disabled: string
}

export type ThemeChip = {
  background: string
  highlightedBackground: string
  text: string
  highlightedText: string
}

export type ThemeButton = {
  tertiaryButton: string
  pressedOpacity: string
}

export type ThemeCard = {
  background: string
  secondaryBackground: string
  headBackground: string
  highlight: string
  selector: string
}

interface FilterTag {
  background: string
  border: string
  text: string
}

export type DataFilterConfig<T, TSub = void> = {
  group: GroupConfig<T, TSub>
  order: OrderConfig<T, TSub>
  filter: FilterConfig<T, TSub>
  searchKeys: SearchKey<T>[]
}

export type OrderConfig<T, T2 = void> = {
  type: DataFilterConfigType.Order
  config: Config<T, OrderConfigOptions<T, T2>>
}

export type GroupConfig<T, T2 = void> = {
  type: DataFilterConfigType.Group
  config: Config<T, GroupConfigOptions<T, T2>>
}

export type FilterConfig<T, T2 = void> = {
  type: DataFilterConfigType.Filter
  config: Config<T, FilterConfigOptions<T2>>
}

type Config<T, Y> = ([keyof T, i18nKey] | [keyof T, i18nKey, Y])[]

export type i18nKey = keyof typeof translation.en

export type FilterConfigOptions<TSub> =
  | DefaultFilterConfigOption
  | BooleanFilterConfigOption
  | ArrayFilterConfigOption<TSub>
  | DateRangeFilterConfigOption

export type OrderConfigOptions<T, TSub = void> = {
  modifier?: 'inverse'
  textKey?: keyof T | LanguageSelectType<keyof T>
  textProvider?: (value: unknown) => i18nKey
  isDefault?: boolean
  subObjectKey?: keyof TSub
  subObjectValueProvider?: (subObj: TSub, obj: T) => string | number | undefined | null
}

export type LanguageSelectType<T> = Record<Language, T>

export type DefaultFilterConfigOption = {
  filterType: undefined
}

export type BooleanFilterConfigOption = {
  filterType: 'boolean'
  trueText: i18nKey
  falseText: i18nKey
}

export type ArrayFilterConfigOption<TSub> = {
  filterType: 'array'
  valueExtractor: (el: TSub) => string
}

export type DateRangeFilterConfigOption = {
  filterType: 'dateRange'
}

export enum DataFilterConfigType {
  Group = 'group',
  Order = 'order',
  Filter = 'filter',
  ApiFilter = 'api-filter',
}

export type GroupConfigOptions<T, TSub = void> = OrderConfigOptions<T, TSub>

export type ApiFilterDataSorter<T> = CommonDataSorter<T> & {
  active: boolean
  value?: string | number | null
  text: string
}

type CommonDataSorter<T> = {
  id: string
  dataKey: keyof T
  textKey: i18nKey
}

export type DataFilterValue = {
  id: string
  value: string
  active?: boolean
  count?: number
  booleanTypeTrueTag?: boolean
  arrayElementId?: number
  isDateRangeMax?: boolean
}

export type PackagesSettings = {
  enableRounding: boolean
  rounding: {
    widthRoundingDown: number
    lengthRoundingDown: number
    lengthIncreaseBy: 0 | 5 | 10
  }
  maxWidth: number
}

export type LotSettings = {
  enableDoubleDiameter: boolean
  doubleDiameter: number
  standingUp: string
  calibrate: number
}

export type GeneralSettings = {
  syncOnlyFinished: boolean
  enableVoice: boolean
}

export type GroupDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Group
  active: boolean
  order?: number
  options?: GroupConfigOptions<T, TSub>
}

export interface GroupedData<T> {
  key: string | null
  title: string
  data: T[]
}

export type OrderDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Order
  active: boolean
  order?: number
  options?: OrderConfigOptions<T, TSub>
}

export type FilterDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Filter
  values: DataFilterValue[]
  options?: FilterConfigOptions<TSub>
}

export type ScreenStatus = { open: boolean }

export type ScreenHeight = {
  detail: number
  header: number
}

export interface ModalScreenRef {
  handleGoBack: () => void
}

export type ModalScreenProps<T> = {
  controller?: ModalController<T>
}

export type LastConnectedInfo = {
  lastConnectedMeter: BluetoothDevice | undefined
  lastConnectedCaliber: BluetoothDevice | undefined
  continueWithoutDevice: boolean
}
export interface BluetoothDevice extends Peripheral {
  deviceType?: string
  manufacturer?: string
  lastConnectionDate?: number
}

export type ConfiguredDevice = {
  deviceId: string
  name: string
  type: string
  manufacturer: string
}

export type LoadingType = 'reloading' | 'hiddenLoading' | 'aborted' | 'init' | 'catched' | false

export type InfiniteLoadingType = LoadingType | 'loadMore'

export enum TimeFormat {
  TIME = 'time',
  TIME_WITH_DIMENSIONS = 'timeWithDimensions',
  COUNTER = 'counter',
}

export type TimeOverflowHandling = 'wrap' | 'limit' | 'none'

export type Address = {
  line1: string
  line2: string
  zip: string
  state: string
  city: string
  country: string
}
