const conversionUtils = {
  meterToMillimeter: (meter: number): number => {
    return meter * 1000
  },
  meterToInch: (meter: number): number => {
    return meter * 39.3701
  },
  meterToFeet: (meter: number): number => {
    return meter * 3.28084
  },
  millimeterToInch: (millimeter: number): number => {
    return millimeter * 0.0393701
  },
  millimeterToFeet: (millimeter: number): number => {
    return millimeter * 0.00328084
  },
  millimeterToMeter: (millimeter: number): number => {
    return millimeter / 1000
  },
  inchToMillimeter: (inch: number): number => {
    return inch * 25.4
  },
  inchToMeter: (inch: number): number => {
    return inch * 0.0254
  },
  inchToFeet: (inch: number): number => {
    return inch / 12
  },
  feetToMillimeter: (feet: number): number => {
    return feet * 304.8
  },
  feetToInch: (feet: number): number => {
    return feet * 12
  },
  feetToMeter: (feet: number): number => {
    return feet * 0.3048
  },
  centimeterToMillimeter: (centimeter: number): number => {
    return centimeter * 10
  },
  millimeterToCentimeter: (millimeter: number): number => {
    return millimeter / 10
  },
}

export default conversionUtils
