import { IM, IMLayout, IMStyle, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { Animated, NativeScrollEvent, NativeSyntheticEvent, StyleSheet, TouchableOpacity, View } from 'react-native'
import SwipeableFlatList from 'rn-gesture-swipeable-flatlist'

import AnimatedButton, { AnimatedButtonHideEvent } from '../../components/AnimatedButton'
import LotCard from '../../components/cards/LotCard'
import TextWithIcon from '../../components/TextWithIcon'
import { ADD_PACKAGE_BUTTON_ID } from '../../constants/ButtonIds'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { useData } from '../../contexts/DataContext'
import { useLotFilterContext } from '../../contexts/LotFilterContext'
import { useLots } from '../../hooks/useLots'
import LotCreateModal, { LotCreateModalControllerData } from '../../modals/LotCreateModal'
import { ThemeColorExpanded } from '../../types'
import { Lot } from '../../types/types'

const buttonAnimationValue = new Animated.Value(0)
export default function LotsView() {
  const rootNavigation = useNavigation()
  const { lots } = useData()
  const { showOnlyOpen, searchString } = useLotFilterContext()
  const filteredLots = useMemo(() => {
    let result = lots
    if (showOnlyOpen) {
      result = result.filter(l => !l.closed)
    }
    if (searchString !== '') {
      result = result.filter(l => l.name.toLowerCase().includes(searchString.toLowerCase()))
    }
    return result
  }, [lots, showOnlyOpen, searchString])
  const { deleteLot } = useLots()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const [listKey, setListKey] = useState(0)
  const packageCreateModal = useModalController<LotCreateModalControllerData>()
  const { emit } = useEvent<AnimatedButtonHideEvent>({ key: EVENT_KEYS.ANIMATED_BUTTON_SHOULD_HIDE })

  const editItem = (item: Lot) => {
    packageCreateModal.show({ lotId: item.tmpId })
  }

  const handleDeletePress = (item: Lot) => {
    deleteItem(item)
  }

  const deleteItem = (item: Lot) => {
    deleteLot(item.tmpId)
    setListKey(prevKey => prevKey + 1)
  }

  const handleCreate = (id: string) => {
    rootNavigation.navigate('LotsMeasure', { lotTmpId: id })
  }

  const onCardPress = (l: Lot) => {
    if (!l.closed) {
      rootNavigation.navigate('LotsMeasure', { lotTmpId: l.tmpId })
    }
  }

  const renderLotCard = ({ item }: { item: Lot }) => (
    <View style={styles.packageCardContainer}>
      <LotCard lot={item} onPress={() => onCardPress(item)} />
    </View>
  )

  const renderRightActions = useCallback(
    (item: Lot) => (
      <IM.View style={styles.actionContainer}>
        <TouchableOpacity onPress={() => editItem(item)} style={[styles.action, styles.leftAction, { backgroundColor: theme.primary }]}>
          <TextWithIcon alignIcon="top" icon={['fal', 'pen']} iconSize={22}>
            {i18n.t('EDIT')}
          </TextWithIcon>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleDeletePress(item)} style={[styles.action, styles.rightAction, { backgroundColor: theme.error }]}>
          <TextWithIcon alignIcon="top" icon={['fal', 'trash']} iconSize={22}>
            {i18n.t('DELETE')}
          </TextWithIcon>
        </TouchableOpacity>
      </IM.View>
    ),
    [deleteItem, editItem]
  )

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    const contentOffset = event.nativeEvent.contentOffset.y
    emit({ id: ADD_PACKAGE_BUTTON_ID, hide: contentOffset > 0 })
  }

  return (
    <>
      <SwipeableFlatList
        key={listKey}
        data={filteredLots}
        renderItem={renderLotCard}
        style={styles.packageList}
        renderRightActions={renderRightActions}
        onScroll={onScroll}
      />
      <AnimatedButton
        style={styles.button}
        id={ADD_PACKAGE_BUTTON_ID}
        value={buttonAnimationValue}
        icon={['fal', 'plus']}
        iconSize={50}
        onPress={() => packageCreateModal.show({})}
      />
      <LotCreateModal controller={packageCreateModal} onCreateCallback={(id: string) => handleCreate(id)} />
    </>
  )
}

const styles = StyleSheet.create({
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  packageList: {
    width: '100%',
    padding: IMLayout.horizontalMargin,
  },
  actionContainer: {
    flexDirection: 'row',
    marginBottom: 10,
    marginRight: 5,
  },
  action: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: 75,
    height: '100%',
  },
  leftAction: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginTop: 5,
  },
  rightAction: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: 5,
  },
  packageCardContainer: {
    overflow: 'hidden',
  },
})
