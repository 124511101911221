import { IMLayout, IMStyle, useAlert, useLanguage } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect, useState } from 'react'
import { Animated, StyleSheet } from 'react-native'
import uuid from 'react-native-uuid'

import AnimatedButton from '../../components/AnimatedButton'
import DateInput from '../../components/DateInput'
import NumberInput from '../../components/NumberInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import LotTypeSelector from '../../components/selectors/LotTypeSelector'
import MeasurerSelector from '../../components/selectors/MeasurerSelector'
import OriginSelector from '../../components/selectors/OriginSelector'
import QualitySelector from '../../components/selectors/QualitySelector'
import SawmillSelector from '../../components/selectors/SawmillSelector'
import WoodSelector from '../../components/selectors/WoodSelector'
import TextInput from '../../components/TextInput'
import { ADD_PACKAGE_BUTTON_ID } from '../../constants/ButtonIds'
import { STORAGE_KEYS } from '../../constants/keys'
import { useData } from '../../contexts/DataContext'
import { useLotCreation } from '../../contexts/LotCreationContext'
import { useSettings } from '../../contexts/SettingsContext'
import { useLots } from '../../hooks/useLots'
import { LotSettings } from '../../types'
import { Lot } from '../../types/types'

const now = new Date()

const year = now.getFullYear()
const month = (now.getMonth() + 1).toString().padStart(2, '0')
const day = now.getDate().toString().padStart(2, '0')

const formattedDate = `${year}-${month}-${day}`

type LotCreateViewProps = {
  editId?: string
  onCreate: (id: string) => void
}

const buttonAnimationValue = new Animated.Value(0)

const LotCreateView: React.FC<LotCreateViewProps> = ({ editId, onCreate }) => {
  const { i18n } = useLanguage()
  const { lot, setLot, setIsFormValid, isFormValid } = useLotCreation()
  const { getLotById, addLot } = useLots()
  const { standingUp } = useData()
  const { alert } = useAlert()
  const { lotSettings } = useSettings()
  const [localLotSettings] = useState<LotSettings>(lotSettings)

  useEffect(() => {
    if (editId) {
      fetchLotById(editId)
    } else {
      const newLot: Partial<Lot> = {
        name: '',
        lotType: undefined,
        customerId: undefined,
        woodId: undefined,
        sawmillId: undefined,
        measurerId: undefined,
        notes: '',
        date: formattedDate,
        commission: 0,
        settings: localLotSettings,
      }
      setLot(newLot)
    }
  }, [editId, setLot])

  useEffect(() => {
    const fetchLastLotData = async () => {
      const lastLotDataString = await AsyncStorage.getItem(STORAGE_KEYS.LAST_LOT_DATA)
      if (lastLotDataString) {
        const savedLot: Partial<Lot> = JSON.parse(lastLotDataString) as Partial<Lot>
        if (lotSettings.enableDoubleDiameter) {
          savedLot.lotType = { value: 'Diameter1', label: 'DIAMETER_1' }
        }
        setLot({ ...lot, ...savedLot })
      }
    }

    fetchLastLotData().catch(console.error)
  }, [])

  function fetchLotById(id: string) {
    try {
      const existingLot = getLotById(id) as Lot
      setLot(existingLot)
    } catch (error) {
      console.error('Failed to fetch lot:', error)
    }
  }

  function handleChange(value: Partial<Lot>) {
    const updatedLot = { ...lot, ...value }
    setLot(updatedLot)
    validateForm(updatedLot)
  }

  function validateForm(lot2: Partial<Lot>) {
    const requiredFields = [lot2.name, lot2.lotType, lot2.customerId, lot2.woodId, lot2.sawmillId, lot2.measurerId]
    let isValid = requiredFields.every(field => field !== undefined && field !== '')
    if (lot2.lotType?.value === 'StandingUp') {
      const isWoodInStandingUp = standingUp.find(s => s.woodId === lot2.woodId)
      if (!isWoodInStandingUp) {
        isValid = false
      }
    }
    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm(lot)
  }, [lot, validateForm])

  const handlePress = () => {
    handleCreate().catch(console.error)
  }

  const handleCreate = async () => {
    if (isFormValid) {
      try {
        const serializedLot = JSON.stringify(lot)
        await AsyncStorage.setItem(STORAGE_KEYS.LAST_LOT_DATA, serializedLot)
      } catch (error) {
        console.error('Error saving lot data:', error)
      }

      const updatedLot: Lot = {
        ...lot,
        tmpId: editId ? editId : uuid.v4(),
        closed: false,
        trunks: [],
        settings: lot.settings ?? lotSettings,
      } as Lot

      setLot(updatedLot)
      if (!editId) {
        addLot(updatedLot)
      }
      onCreate(updatedLot.tmpId)
    } else {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
    }
  }

  return (
    <>
      <ScrollViewForm>
        <DateInput
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('DATE')}
          value={lot.date || formattedDate}
          editable={false}
          onChangeDate={() => handleChange({ date: formattedDate })}
          spacing={['all']}
        />
        <NumberInput
          title={i18n.t('COMMISSION')}
          placeholder={i18n.t('COMMISSION')}
          value={lot.commission || 0}
          disableFastInputs
          onEndEditing={value => handleChange({ commission: Number(value.nativeEvent.text) })}
          spacing={['all']}
        />
        <TextInput
          required
          title={i18n.t('NAME')}
          placeholder={i18n.t('NAME')}
          value={lot.name || ''}
          onChangeText={value => handleChange({ name: value })}
          spacing={'all'}
        />

        <TextInput
          title={i18n.t('NOTES')}
          placeholder={i18n.t('NOTES')}
          value={lot.notes || ''}
          onChangeText={value => handleChange({ notes: value })}
          spacing={'all'}
        />
        {!lotSettings.enableDoubleDiameter && (
          <LotTypeSelector
            editable={true}
            required
            selectedLotType={lot.lotType}
            onChange={value => handleChange({ lotType: value })}
            spacing={'all'}
          />
        )}
        <CustomerSelector
          required
          selectedCustomerId={lot.customerId}
          editable={true}
          onChange={value => handleChange({ customerId: value?.customerId })}
          spacing={['all']}
        />
        <WoodSelector
          required
          selectedWoodId={lot.woodId}
          editable={true}
          onChange={value => handleChange({ woodId: value?.woodId })}
          spacing={['all']}
          showReduction
        />
        <QualitySelector
          required
          selectedQualityId={lot.qualityId}
          editable={true}
          onChange={value => handleChange({ qualityId: value?.qualityId })}
          spacing={['all']}
        />
        <OriginSelector
          required
          selectedOriginId={lot.originId}
          editable={true}
          onChange={value => handleChange({ originId: value?.originId })}
          spacing={['all']}
        />
        <SawmillSelector
          required
          selectedSawmillId={lot.sawmillId}
          editable={true}
          onChange={value => handleChange({ sawmillId: value?.sawmillId })}
          spacing={['all']}
        />
        <MeasurerSelector
          required
          selectedMeasurerId={lot.measurerId}
          editable={true}
          onChange={value => handleChange({ measurerId: value?.measurerId })}
          spacing={['all']}
        />
      </ScrollViewForm>
      <AnimatedButton
        style={styles.button}
        id={ADD_PACKAGE_BUTTON_ID}
        value={buttonAnimationValue}
        icon={['fal', 'check']}
        iconSize={50}
        onPress={() => handlePress()}
      />
    </>
  )
}

const styles = StyleSheet.create({
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
})

export default LotCreateView
