import React, { createContext, ReactNode, useContext, useState } from 'react'

interface FilterContextState {
  searchString: string
  showOnlyOpen: boolean
  setSearchString: (value: string) => void
  setShowOnlyOpen: (value: boolean) => void
}

const LotFilterContext = createContext<FilterContextState | undefined>(undefined)

export const LotFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchString, setSearchString] = useState<string>('')
  const [showOnlyOpen, setShowOnlyOpen] = useState<boolean>(false)

  return <LotFilterContext.Provider value={{ searchString, showOnlyOpen, setSearchString, setShowOnlyOpen }}>{children}</LotFilterContext.Provider>
}

export const useLotFilterContext = (): FilterContextState => {
  const context = useContext(LotFilterContext)
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider')
  }
  return context
}
