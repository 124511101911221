import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'
import { Divider } from 'react-native-elements'

import { useData } from '../../contexts/DataContext'
import { ThemeColorExpanded } from '../../types'
import { Lot } from '../../types/types'
import Icon from '../Icon'

export type LotCardProps = { lot: Lot } & CardProps

export default function LotCard({ lot, ...cardProps }: LotCardProps) {
  const { i18n } = useLanguage()
  const { customers, woods, sawmills, qualities } = useData()
  const { theme } = useTheme<ThemeColorExpanded>()

  const customer = customers.find(c => c.customerId === lot.customerId)
  const wood = woods.find(w => w.woodId === lot.woodId)
  const sawmill = sawmills.find(s => s.sawmillId === lot.sawmillId)
  const quality = qualities.find(q => q.qualityId === lot.qualityId)

  return (
    <IM.Card {...cardProps} style={styles.card}>
      <IM.View style={styles.header}>
        <IM.View style={styles.packageNameContainer}>
          <IM.Text style={styles.packageName}>{lot.name}</IM.Text>
          {lot.closed && <Icon icon={['fal', 'badge-check']} size={17} color={theme.radioButton} style={styles.icon} />}
        </IM.View>
        <IM.Text style={styles.boardsCount}>
          {i18n.t('TRUNKS_NUMBER')} {lot.trunks.length}
        </IM.Text>
      </IM.View>
      <Divider style={styles.divider} />
      <IM.View style={styles.badgeContent}>
        <IM.View style={styles.column}>
          <IM.View style={styles.row}>
            <Icon icon={['fal', 'building']} size={17} color={theme.header.main.text.secondary} style={styles.icon} />
            <IM.Text>{customer?.customerName}</IM.Text>
          </IM.View>
          <IM.View style={styles.row}>
            <Icon icon={['fal', 'calendar-clock']} size={17} color={theme.header.main.text.secondary} style={styles.icon} />
            <IM.Text>{lot.date}</IM.Text>
          </IM.View>
        </IM.View>
        <IM.View style={styles.column}>
          <IM.View style={styles.row}>
            <Icon icon={['fal', 'file-certificate']} size={17} color={theme.header.main.text.secondary} style={styles.icon} />
            <IM.Text>{quality?.qualityName}</IM.Text>
          </IM.View>
          <IM.View style={styles.row}>
            <Icon icon={['fal', 'tree-deciduous']} size={17} color={theme.header.main.text.secondary} style={styles.icon} />
            <IM.Text>{wood?.woodName}</IM.Text>
          </IM.View>
          <IM.View style={styles.row}>
            <Icon icon={['fal', 'hourglass-clock']} size={17} color={theme.header.main.text.secondary} style={styles.icon} />
            <IM.Text>{sawmill?.sawmillName}</IM.Text>
          </IM.View>
        </IM.View>
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  card: {
    margin: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  packageName: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  icon: {
    marginLeft: 5,
    marginRight: 5,
  },
  packageNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  boardsCount: {
    fontWeight: 'bold',
  },
  divider: {
    marginBottom: 10,
  },
  badgeContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    padding: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
