import { IM } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { LotFilterProvider } from '../../contexts/LotFilterContext'
import LotsHeader from './LotsHeader'
import LotsView from './LotsView'

export default function LotsScreen() {
  const [busy, setBusy] = useState(false)

  const handleSaving = (saving: boolean) => {
    setBusy(saving)
  }

  return (
    <LotFilterProvider>
      <Screen>
        <Screen.Header>
          <LotsHeader onSaving={handleSaving} />
        </Screen.Header>
        <Screen.Content>
          {busy && (
            <IM.View style={styles.loadingContainer}>
              <IM.LoadingSpinner isVisible={true} />
            </IM.View>
          )}
          <LotsView />
        </Screen.Content>
      </Screen>
    </LotFilterProvider>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})
