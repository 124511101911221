import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Grade } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import GradeCard from '../cards/GradeCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Grade | undefined) => void
  required?: boolean
  selectedGradeId?: number
}

export default function GradeSelector({ onChange, selectedGradeId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { grades, organizationId, setGrades } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(grades, search, ['gradeName', 'gradeId', 'gradeCode'])
  const render = ({ item }: SectionListRenderItemInfo<Grade, ListSection<Grade>>, onPress?: () => void) => {
    return <GradeCard grade={item} onPress={onPress} selected={item.gradeId === selectedGradeId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Grade | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveGrade(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveGrade = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postGrade({ gradeName: inputValue, organizationId: organizationId })
      const res = await api.getGrades({ organizationId: organizationId })
      setGrades(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedGrade = useMemo(() => grades?.find(elem => elem.gradeId === selectedGradeId), [grades, selectedGradeId])

  return (
    <SelectInput
      id={'gradeId'}
      data={filteredData}
      value={selectedGrade}
      loading={false}
      refresh={undefined}
      title={i18n.t('GRADE')}
      screenTitle={i18n.t('GRADE')}
      noDataMessage={i18n.t('NO_GRADE')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.gradeName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      {...props}
    />
  )
}
