import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import PressableIcon from '../../components/PressableIcon'
import useScreen from '../../components/screen/hooks/useScreen'
import { useData } from '../../contexts/DataContext'
import { useSettings } from '../../contexts/SettingsContext'
import { useLots } from '../../hooks/useLots'
import { ThemeColorExpanded } from '../../types'
import BaseHeader from '../BaseHeader'

type LotsHeaderProps = { onSaving: (busy: boolean) => void }

const LotsHeader = ({ onSaving }: LotsHeaderProps) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { lots, customers } = useData()
  const { generalSettings } = useSettings()
  const { deleteLot } = useLots()
  const [busy, setBusy] = useState(false)
  const { toggleDetailOpen } = useScreen()

  const onSendToCloud = () => {
    saveLots()
      .catch(error => {
        console.error('savePackages error', error)
      })
      .finally(() => {
        setBusy(false)
      })
  }

  useEffect(() => {
    onSaving(busy)
  }, [busy, onSaving])

  const saveLots = async () => {
    for (const lot of lots) {
      const isLotClosed = generalSettings.syncOnlyFinished ? lot.closed : true
      if (isLotClosed) {
        await api
          .postLots({
            organizationId: customers[0].organizationId,
            lotName: lot.name,
            lotNumber: lot.name,
            lotCommission: lot.commission || 0,
            addDate: lot.date,
            editDate: lot.date,
            flgClosed: lot.closed,
            customerId: lot.customerId,
            woodId: lot.woodId,
            measurerId: lot.measurerId,
            sawmillId: lot.sawmillId,
            originId: lot.originId,
            lotType: lot.lotType.value,
            trunks: lot.trunks,
          })
          .then(() => {
            deleteLot(lot.tmpId)
          })
          .catch(error => {
            console.error('savePackages error', error)
          })
      }
    }
  }

  const rightComponent = (
    <>
      <IM.View style={styles.container}>
        <PressableIcon icon={['fal', 'filter']} size={21} color={theme.header.main.text.primary} onPress={toggleDetailOpen} />
        <PressableIcon icon={['fal', 'cloud-arrow-up']} size={24} color={theme.header.main.text.primary} onPress={onSendToCloud} />
      </IM.View>
    </>
  )

  return <BaseHeader title={i18n.t('LOTS')} rightComponent={rightComponent} />
}

export default LotsHeader

const styles = StyleSheet.create({
  container: { flexDirection: 'row' },
})
