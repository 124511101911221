import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import Text from '../../components/Text'
import { ThemeColorExpanded } from '../../types'
import { Board } from '../../types/types'

type Props = {
  board: Board
  spacing?: SpacingProps
  borderless?: boolean
  forceLayout?: false | 'small' | 'medium' | 'large'
  style?: StyleProp<ViewStyle>
  index: number
}

const SwipeCarouselCard = memo(function SwipeCarouselCard({ board, spacing, borderless, style }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.Card
      noContentSpacing
      borderless={borderless}
      spacing={spacing}
      style={[{ backgroundColor: theme.button.background, borderRadius: IMLayout.borderRadius }, IMLayout.shadow, style]}>
      <IM.View style={styles.container}>
        <IM.View style={styles.infoContainer}>
          <Text style={styles.label}>
            {i18n.t('BOARD_N')}
            {board.orderItemId}
          </Text>
          <IM.View style={styles.rowContainer}>
            <Text style={styles.label}>{i18n.t('LENGTH')}</Text>
            <Text style={styles.value}>{board.lengthUm + ' ' + board.boardLength.toString()}</Text>
          </IM.View>
          <IM.View style={styles.rowContainer}>
            <Text style={styles.label}>{i18n.t('WIDTH')}</Text>
            <Text style={styles.value}>{board.widthUm + ' ' + board.boardWidth.toString()}</Text>
          </IM.View>
        </IM.View>
      </IM.View>
    </IM.Card>
  )
})

export default SwipeCarouselCard

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  infoContainer: {
    alignItems: 'center',
  },
  label: {
    fontSize: 24,
    color: '#fff',
    marginBottom: 10,
  },
  rowContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  value: {
    fontWeight: 'bold',
    fontSize: 64,
    color: '#fff',
  },
})
