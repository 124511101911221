import { IM } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { PackageFilterProvider } from '../../contexts/PackageFilterContext'
import { PackageFilterHeader } from './PackageFilterHeader'
import PackagesHeader from './PackagesHeaders'
import PackagesView from './PackagesView'

export default function PackagesScreen() {
  const [busy, setBusy] = useState(false)

  const handleSaving = (saving: boolean) => {
    setBusy(saving)
  }
  return (
    <PackageFilterProvider>
      <Screen>
        <Screen.Header>
          <PackagesHeader onSaving={handleSaving} />
        </Screen.Header>
        <Screen.DetailHeader>
          <PackageFilterHeader />
        </Screen.DetailHeader>
        <Screen.Content>
          {busy && (
            <IM.View style={styles.loadingContainer}>
              <IM.LoadingSpinner isVisible={true} />
            </IM.View>
          )}
          <PackagesView />
        </Screen.Content>
      </Screen>
    </PackageFilterProvider>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})
