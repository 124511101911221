import { IM, IMStyle, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation } from '@react-navigation/native'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { FlatList, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'

import PackageBoardCard from '../../components/cards/PackageBoardCard'
import Separator from '../../components/cards/Separator'
import SwipeCarouselCard from '../../components/cards/SwipeCarouselCard'
import CustomKeyboard from '../../components/CustomKeyboard'
import PressableIcon from '../../components/PressableIcon'
import { useData } from '../../contexts/DataContext'
import { useSettings } from '../../contexts/SettingsContext'
import useBluetoothNotifications from '../../hooks/useBluetoothNotification'
import { useConnectedDevices } from '../../hooks/useConnectedDevices'
import useNotificationSounds from '../../hooks/useNotificationSounds'
import { usePackage } from '../../hooks/usePackage'
import EditBoardModal, { EditBoardModalData } from '../../modals/EditBoardModal'
import { ThemeColorExpanded } from '../../types'
import { Board, Package } from '../../types/types'
import { packageUtils } from '../../utils/packageUtils'
import conversionUtils from '../../utils/umConversion'

interface PackageMeasureViewProps {
  packageTmpId: string
  swipeMode: boolean
}

interface PackageMeasureViewRef {
  getPkg: () => Package | undefined
}

const PackageMeasureView = ({ packageTmpId, swipeMode }: PackageMeasureViewProps, ref: React.ForwardedRef<PackageMeasureViewRef>) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { lastConnectedInfo } = useConnectedDevices()

  const { lastMeasure } = useBluetoothNotifications(lastConnectedInfo?.lastConnectedMeter, onMeasurement)
  const navigation = useNavigation()

  const { i18n } = useLanguage()
  const { generalSettings } = useSettings()
  const { playAlertSound } = useNotificationSounds()

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { packages, woods } = useData()
  const { pkg, setPackage } = usePackage(packageTmpId)
  const [currentBoard, setCurrentBoard] = useState<Board>({
    orderItemId: pkg?.boards.length ? pkg.boards.length + 1 : 1,
    boardHeight: pkg?.thickness || 0,
    boardLength: getBoardLengthBasedOnPackageType(pkg),
    boardWidth: 0,
    widthUm: pkg?.ums?.value?.substring(2, 4) ?? 'cm',
    lengthUm: pkg?.ums?.value?.substring(0, 2) ?? 'cm',
    addDate: new Date().toISOString(),
    boardNumber: 1,
    boardQuantity: pkg?.packageType.value === 'Prismatic' || pkg?.packageType.value === 'Trasversal' || pkg?.packageType.value === 'Weight' ? 0 : 1,
  })
  const [maxBoardLength, setMaxBoardLength] = useState(0)
  const placeholder = useMemo(() => {
    if (currentBoard.boardLength === 0) {
      return i18n.t('LENGTH') + ' ' + currentBoard.lengthUm
    } else if (currentBoard.boardWidth === 0) {
      return i18n.t('WIDTH') + ' ' + currentBoard.widthUm
    }
    return ''
  }, [currentBoard])
  const editBoardModalController = useModalController<EditBoardModalData>()

  const inputRef = useRef<TextInput>(null)

  const volume = useMemo(() => {
    if (!pkg) return 0

    if (pkg.boards.length !== currentBoard.orderItemId + 1) {
      setCurrentBoard(prev => ({
        ...prev,
        orderItemId: pkg.boards.length + 1,
      }))
    }

    const totalVolume = pkg.boards.reduce((currentTotalVolume, board) => {
      let lengthInMeters = board.boardLength || 0
      let widthInMeters = board.boardWidth || 0

      if (board.lengthUm === 'cm') {
        lengthInMeters /= 100
      } else if (board.lengthUm === 'mm') {
        lengthInMeters /= 1000
      }
      if (board.widthUm === 'cm') {
        widthInMeters /= 100
      } else if (board.widthUm === 'mm') {
        widthInMeters /= 1000
      }

      let quantityFactor = 1
      if (pkg.packageType.value !== 'Trasversal') {
        quantityFactor = board.boardQuantity
      }

      const boardVolume = lengthInMeters * widthInMeters * (board.boardHeight / 1000) * quantityFactor
      return currentTotalVolume + boardVolume
    }, 0)
    return parseFloat((totalVolume / 10000).toFixed(3))
  }, [pkg?.boards, pkg?.packageType])

  useEffect(() => {
    const loadKeyboardState = async () => {
      try {
        const storedState = await AsyncStorage.getItem('keyboardState')
        if (storedState !== null) {
          setIsKeyboardOpen(storedState === 'true')
        }
      } catch (error) {
        console.error('Failed to load keyboard state from AsyncStorage:', error)
      }
    }

    loadKeyboardState().catch(console.error)
  }, [])

  useEffect(() => {
    const saveKeyboardState = async () => {
      try {
        await AsyncStorage.setItem('keyboardState', isKeyboardOpen.toString())
      } catch (error) {
        console.error('Failed to save keyboard state to AsyncStorage:', error)
      }
    }
    saveKeyboardState().catch(console.error)
  }, [isKeyboardOpen])

  useEffect(() => {
    if (pkg && volume !== pkg.volume) {
      setPackage({
        ...pkg,
        volume: volume,
      })
    }
  }, [volume])

  useImperativeHandle(ref, () => ({
    getPkg: () => pkg,
  }))

  // const area = useMemo(() => {
  //   if (!pkg || pkg.packageType.value !== 'Parquet') return 0
  //   return pkg.boards.reduce((totalArea, board) => {
  //     let lengthInMeters = board.boardLength || 0
  //     let widthInMeters = board.boardWidth || 0
  //     if (board.lengthUm === 'cm') {
  //       lengthInMeters /= 100
  //     } else if (board.lengthUm === 'mm') {
  //       lengthInMeters /= 1000
  //     }
  //     if (board.widthUm === 'cm') {
  //       widthInMeters /= 100
  //     } else if (board.widthUm === 'mm') {
  //       widthInMeters /= 1000
  //     }
  //     const boardArea = lengthInMeters * widthInMeters
  //     return totalArea + boardArea
  //   }, 0)
  // }, [pkg?.boards])

  useEffect(() => {
    if (pkg) {
      const maxLength = pkg.boards.reduce((max, board) => {
        return board.boardLength > max ? board.boardLength : max
      }, 0)
      setMaxBoardLength(maxLength)
    }
  }, [pkg?.boards])

  // TODO: fix not all code paths return a value
  //@ts-ignore temporary solution for web to work since "not all code paths return a value" error
  function onMeasurement(measurement: string) {
    playAlertSound()
    let measure: number
    if (measurement.split(' ').pop() && measurement.split(' ').pop()?.trim() === 'cm' && !pkg?.settings.enableRounding) {
      const measurementValue = measurement.match(/[\d.]+/)
      if (pkg && measurementValue && parseFloat(measurementValue[0]) === 0) {
        const updatedBoards = [...pkg.boards]
        updatedBoards.pop()
        setPackage({
          ...pkg,
          boards: updatedBoards,
        })
        return
      }
      if (measurementValue) {
        measure = parseFloat(measurementValue[0])
        measure = conversionUtils.centimeterToMillimeter(measure)
        if (!pkg) return
        const currentWood = woods.find(w => w.woodId === pkg.woodId)
        const result = packageUtils.handleInputChange(
          measure.toString(),
          pkg?.packageType,
          pkg?.settings,
          currentBoard,
          pkg,
          'fromMeter',
          generalSettings.enableVoice,
          currentWood
        )
        const updatedBoard = result.changedBoard
        if (updatedBoard) {
          setCurrentBoard(updatedBoard)
        }
        if (result.changePackage) {
          const automaticLengthsPackages = packages.filter(p => p.packageType.value === 'AutomaticLength')
          if (automaticLengthsPackages.length < 2) {
            return
          } else {
            if (currentBoard.boardLength === 0 || (pkg.settings.enableRounding && pkg.settings.maxWidth < measure)) {
              if (pkg?.settings.enableRounding) {
                measure = Math.floor(pkg.settings.rounding.lengthRoundingDown + measure)
              }
              const filteredPackages = packages.filter(p => p.packageType.value === 'AutomaticLength' && p.maxLength && p.maxLength > measure)
              const minPackage = filteredPackages.reduce(
                (min, p) => (p.maxLength && min.maxLength && p.maxLength < min.maxLength ? p : min),
                filteredPackages[0]
              )
              if (minPackage && minPackage.tmpId === packageTmpId) {
                return { changedBoard: { ...currentBoard, boardLength: measure }, changePackage: undefined }
              } else {
                navigation.navigate('PackagesMeasure', { packageTmpId: minPackage.tmpId })
                return
              }
            } else if (currentBoard.boardWidth === 0) {
              if (pkg?.settings.enableRounding) {
                measure = Math.floor(pkg.settings.rounding.widthRoundingDown + measure)
              }
              const board2 = { ...currentBoard, boardWidth: measure }
              const newBoards = [...pkg.boards, board2]
              pkg.boards = newBoards

              return {
                changedBoard: {
                  orderItemId: pkg.boards.length + 1,
                  boardHeight: pkg.thickness || 0,
                  boardLength: board2.boardLength ?? 0,
                  boardWidth: 0,
                  widthUm: pkg.settings.enableRounding ? pkg.ums?.value?.substring(2, 4) ?? 'cm' : 'mm',
                  lengthUm: pkg.settings.enableRounding ? pkg.ums?.value?.substring(0, 2) ?? 'cm' : 'mm',
                  addDate: new Date().toISOString(),
                  boardNumber: pkg.boards.length + 1,
                  boardQuantity: 1,
                },
                changePackage: undefined,
              }
            }
            return
          }
        }
      }
      return
    } else {
      if (!pkg) return
      const measurementValue = measurement.match(/[\d.]+/)
      if (measurementValue && parseFloat(measurementValue[0]) === 0) {
        const updatedBoards = [...pkg.boards]
        updatedBoards.pop()
        setPackage({
          ...pkg,
          boards: updatedBoards,
        })
        return
      }

      const currentWood = woods.find(w => w.woodId === pkg.woodId)
      const updateResult = packageUtils.handleInputChange(
        measurement,
        pkg?.packageType,
        pkg?.settings,
        currentBoard,
        pkg,
        'fromMeter',
        generalSettings.enableVoice,
        currentWood
      )

      const updatedBoard = updateResult.changedBoard
      const updatedPackage = updateResult.pkg

      if (updatedBoard) {
        setCurrentBoard(updatedBoard)
      }
      if (updatedPackage) {
        setPackage(updatedPackage)
      }
      return
    }
  }

  function getBoardLengthBasedOnPackageType(pack: Package | undefined) {
    if (!pack) return 0
    if (pack?.packageType?.value === 'Weight') {
      return 300
    } else if (pack.boards.length > 0) {
      return pack.boards[pack.boards.length - 1].boardLength
    } else {
      return 0
    }
  }

  const toggleKeyboard = () => {
    if (isKeyboardOpen) {
      inputRef.current?.blur()
    } else {
      inputRef.current?.focus()
    }
    setIsKeyboardOpen(!isKeyboardOpen)
  }

  const onPressBoard = (board: Board) => {
    if (!pkg) return
    editBoardModalController.show({ packageId: pkg.tmpId, board: board })
  }

  const renderMeasurementCard = ({ item }: { item: Board }) => {
    if (!pkg) {
      return null
    }

    return (
      <TouchableOpacity onPress={() => onPressBoard(item)}>
        <PackageBoardCard type={pkg.packageType} spacing={['right', 'left']} board={item} />
      </TouchableOpacity>
    )
  }

  const handleKeyPress = (key: string) => {
    setInputValue(prev => prev + key)
  }

  const handleDeletePress = () => {
    setInputValue(prev => prev.slice(0, -1))
  }

  const handleSubmitPress = () => {
    if (!pkg) return
    if (inputValue === '0') {
      const updatedBoards = [...pkg.boards]
      updatedBoards.pop()
      setPackage({
        ...pkg,
        boards: updatedBoards,
      })
      setInputValue('')
      return
    }
    const currentWood = woods.find(w => w.woodId === pkg.woodId)
    const result = packageUtils.handleInputChange(
      inputValue,
      pkg?.packageType,
      pkg?.settings,
      currentBoard,
      pkg,
      'fromInput',
      generalSettings.enableVoice,
      currentWood
    )
    const updatedBoard = result.changedBoard
    if (updatedBoard) {
      setCurrentBoard(updatedBoard)
    }
    setInputValue('')
  }

  if (!pkg) {
    return null
  }

  return (
    <View style={styles.container}>
      {!swipeMode ? (
        <>
          <View style={styles.boardContainer}>
            <FlatList
              inverted
              data={[...pkg.boards].reverse()}
              renderItem={renderMeasurementCard}
              keyExtractor={item => item.orderItemId.toString()}
              contentContainerStyle={styles.flatListContent}
            />
            <Separator type="horizontal" spacing={['all']} style={[{ backgroundColor: theme.primary }, styles.separator]} />
            <TouchableOpacity activeOpacity={0.8} style={styles.topBanner}>
              <IM.View style={styles.rowContainer}>
                <IM.Text style={styles.text}>m³ {volume.toFixed(3)}</IM.Text>
                <IM.Text style={styles.text}>{i18n.t('MAX_LENGTH') + ' ' + maxBoardLength.toString()}</IM.Text>
                <IM.Text style={styles.text}>{i18n.t('TOTAL_WEIGHT')}</IM.Text>
                <IM.Text style={styles.text}>{i18n.t('BOARDS') + ' ' + pkg.boards.length.toString()}</IM.Text>
              </IM.View>
            </TouchableOpacity>
            {currentBoard && (
              <PackageBoardCard board={currentBoard} type={pkg.packageType} spacing={['all']} style={[{ backgroundColor: theme.primary }]} />
            )}
          </View>

          <View style={styles.inputContainer}>
            <View style={styles.inputWrapper}>
              <TextInput
                ref={inputRef}
                style={[
                  styles.input,
                  {
                    backgroundColor: theme.header.main.background,
                    color: theme.header.main.text.primary,
                  },
                ]}
                value={inputValue}
                onChangeText={setInputValue}
                onFocus={() => setIsKeyboardOpen(true)}
                onBlur={() => setIsKeyboardOpen(false)}
                showSoftInputOnFocus={false}
                placeholder={placeholder}
                placeholderTextColor={IMStyle.palette.white}
              />
              <TouchableOpacity style={styles.iconButton}>
                <PressableIcon icon={['fal', 'keyboard']} size={24} color={theme.header.main.text.primary} onPress={toggleKeyboard} />
              </TouchableOpacity>
            </View>
          </View>

          {isKeyboardOpen && <CustomKeyboard onKeyPress={handleKeyPress} onDeletePress={handleDeletePress} onSubmitPress={handleSubmitPress} />}
        </>
      ) : (
        <>
          <SwipeCarouselCard board={currentBoard} borderless index={pkg.boards.length + 1} style={styles.card} />
          {/* <SwipeCarousel boards={pkg.boards} currentBoard={currentBoard} /> */}
        </>
      )}
      <EditBoardModal controller={editBoardModalController} />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  topBanner: {
    padding: 10,
    backgroundColor: '#f8f8f8',
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    marginHorizontal: 5,
  },
  boardContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  flatListContent: {
    paddingBottom: 20,
  },
  inputContainer: {
    padding: 5,
    backgroundColor: IMStyle.palette.grey,
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    flex: 1,
    height: 40,
    paddingHorizontal: 10,
    paddingRight: 40,
    paddingTop: 5,
    textAlign: 'center',
  },
  iconButton: {
    position: 'absolute',
    right: 10,
  },
  separator: {
    height: 4,
    borderRadius: 10,
  },
  actionContainer: {
    flexDirection: 'row',
    marginBottom: 10,
    marginRight: 5,
  },
  action: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: 75,
    height: '100%',
  },
  leftAction: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginTop: 5,
  },
  rightAction: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: 5,
  },
  card: {
    width: '100%',
    height: '100%',
  },
})

export default forwardRef(PackageMeasureView)
