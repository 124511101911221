import { IM, openTeamViewer, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, useEffect, useState } from 'react'
import { Platform, ScrollView, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { TextInput } from 'react-native-gesture-handler'

import LanguageSelector, { LanguageSelectorRef } from '../../components/LanguageSelector'
import NumberInput from '../../components/NumberInput'
import Pressable from '../../components/Pressable'
import SettingsRow from '../../components/SettingsRow'
import SettingsRowContainer from '../../components/SettingsRowContainer'
import Switch from '../../components/Switch'
import TextWithIcon from '../../components/TextWithIcon'
import { useData } from '../../contexts/DataContext'
import { useSettings } from '../../contexts/SettingsContext'
import { PackagesSettings, ThemeColorExpanded } from '../../types'
import DevicesModal from '../Devices/DevicesModal'
import RoundingModal from '../Rounding/RoundingModal'

interface SettingsViewProps {
  isBusy: boolean
}

export default function SettingsView({ isBusy }: SettingsViewProps) {
  const navigation = useNavigation()
  const { i18n } = useLanguage()
  const { theme, updateColorScheme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { license, currentOrganization } = useData()

  const [companyOpen, setCompanyOpen] = useState(false)
  const [languageOpen, setLanguageOpen] = useState(false)
  const [themeReady, setThemeReady] = useState(true)
  const [isDark, setIsDark] = useState(colorScheme === 'dark')
  const [showAdvanced, setShowAdvanced] = useState(false)
  const devicesModalController = useModalController()
  const roundingModalController = useModalController()

  const { packageSettings, setPackageSettings, generalSettings, setGeneralSettings, lotSettings, setLotSettings } = useSettings()

  const languageRef = createRef<LanguageSelectorRef>()

  useEffect(() => {
    if ((isDark && colorScheme === 'dark') || (!isDark && colorScheme === 'light')) {
      setThemeReady(true)
    }
  }, [colorScheme, isDark, themeReady])

  useEffect(() => {
    languageOpen
  }, [languageOpen])

  useEffect(() => {
    companyOpen && languageRef.current?.close()
  }, [companyOpen])

  const changeColorScheme = () => {
    updateColorScheme(colorScheme === 'dark' ? 'light' : 'dark')
  }

  const onChangeTheme = () => {
    setThemeReady(false)
    setIsDark(prev => !prev)
    requestAnimationFrame(changeColorScheme)
  }

  const resetApp = () => {
    navigation.reset({
      routes: [
        {
          name: 'BottomTab',
          state: {
            routes: [{ name: 'SettingsStack', state: { routes: [{ name: 'Setting' }] } }],
          },
        },
      ],
    })
  }

  const handlePackageSettingsChange = (updatedSettings: PackagesSettings) => {
    setPackageSettings(updatedSettings)
  }

  const handleSyncOnlyFinishedChange = (value: boolean) => {
    setGeneralSettings({ ...generalSettings, syncOnlyFinished: value })
  }

  const handleDoubleDiameterChange = (value: boolean) => {
    setLotSettings({ ...lotSettings, enableDoubleDiameter: value })
  }

  const handleDoubleDiameterValueChange = (value: number | undefined) => {
    setLotSettings({ ...lotSettings, doubleDiameter: value ?? lotSettings.doubleDiameter })
  }

  const handleCalibrationChange = (value: number | undefined) => {
    setLotSettings({ ...lotSettings, calibrate: value ?? lotSettings.calibrate })
  }

  const handleStandingUpChange = (value: string) => {
    setLotSettings({ ...lotSettings, standingUp: value })
  }

  const handleEnableVoiceChange = (value: boolean) => {
    setGeneralSettings({ ...generalSettings, enableVoice: value })
  }

  const handleMaxWidthChange = (value: number | undefined) => {
    setPackageSettings(prevSettings => ({
      ...prevSettings,
      maxWidth: value ?? prevSettings.maxWidth,
    }))
  }

  return (
    <>
      {isBusy && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          <SettingsRowContainer title={i18n.t('APP')} spacing="vertical">
            <SettingsRow title={i18n.t('SETTINGS_LANGUAGE')} style={[styles.dropdownRow, !!languageOpen && styles.dropdownElevated]}>
              <LanguageSelector
                ref={languageRef}
                onOpen={() => {
                  setCompanyOpen(false)
                  setLanguageOpen(true)
                }}
                onReLogIn={resetApp}
              />
            </SettingsRow>
            <SettingsRow title={i18n.t('SETTINGS_DARKMODE')}>
              {themeReady ? (
                <Switch onValueChange={onChangeTheme} value={isDark} />
              ) : (
                <IM.View style={styles.loading}>
                  <IM.LoadingSpinner isVisible size="small" />
                </IM.View>
              )}
            </SettingsRow>
            <SettingsRow title={i18n.t('SETTINGS_SUPPORT')} style={[styles.dropdownRow]}>
              <Pressable
                onPress={() => {
                  openTeamViewer().catch(err => console.error('Open teamViewer error', err))
                }}>
                <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
                  {i18n.t('OPEN')}
                </TextWithIcon>
              </Pressable>
            </SettingsRow>
          </SettingsRowContainer>

          <SettingsRowContainer title={i18n.t('DEVICES')} spacing="vertical">
            <SettingsRow title={i18n.t('CONNECT_NEW_DEVICE')}>
              <Pressable
                onPress={() => {
                  devicesModalController.show()
                }}>
                <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
                  {i18n.t('OPEN')}
                </TextWithIcon>
              </Pressable>
            </SettingsRow>
          </SettingsRowContainer>

          <SettingsRowContainer title={i18n.t('ORGANIZATION')} spacing="vertical">
            <SettingsRow title={i18n.t('NAME')}>
              <TextInput style={{ color: isDark ? '#fff' : '#000' }} value={currentOrganization?.organizationName} />
            </SettingsRow>
            <SettingsRow title={i18n.t('VAT_NUMBER')}>
              <TextInput style={{ color: isDark ? '#fff' : '#000' }} value={currentOrganization?.vatNumber} />
            </SettingsRow>
            <SettingsRow title={i18n.t('LICENSE')}>
              <TextInput style={{ color: isDark ? '#fff' : '#000' }} value={license} />
            </SettingsRow>
          </SettingsRowContainer>

          <Pressable onPress={() => setShowAdvanced(prev => !prev)}>
            <TextWithIcon secondary alignIcon="right" icon={['fal', showAdvanced ? 'chevron-up' : 'chevron-down']} spacing={'all'} color={theme.text}>
              {i18n.t('ADVANCED').toUpperCase()}
            </TextWithIcon>
          </Pressable>

          {showAdvanced && (
            <>
              <SettingsRowContainer title={i18n.t('GENERAL')} spacing="vertical">
                <SettingsRow title={i18n.t('SYNC_ONLY_FINISHED')}>
                  {themeReady ? (
                    <Switch value={generalSettings.syncOnlyFinished} onValueChange={handleSyncOnlyFinishedChange} />
                  ) : (
                    <IM.View style={styles.loading}>
                      <IM.LoadingSpinner isVisible size="small" />
                    </IM.View>
                  )}
                </SettingsRow>
                <SettingsRow title={i18n.t('ENABLE_VOICE')}>
                  {themeReady ? (
                    <Switch value={generalSettings.enableVoice} onValueChange={handleEnableVoiceChange} />
                  ) : (
                    <IM.View style={styles.loading}>
                      <IM.LoadingSpinner isVisible size="small" />
                    </IM.View>
                  )}
                </SettingsRow>
              </SettingsRowContainer>
              <SettingsRowContainer title={i18n.t('PACKAGES')} spacing="vertical">
                <SettingsRow title={i18n.t('ROUNDING_DEFAULT')}>
                  <Pressable
                    onPress={() => {
                      roundingModalController.show()
                    }}>
                    <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
                      {i18n.t('OPEN')}
                    </TextWithIcon>
                  </Pressable>
                </SettingsRow>
                <SettingsRow title={i18n.t('MAX_WIDTH')}>
                  <IM.View style={styles.label}>
                    <NumberInput disableFastInputs value={packageSettings.maxWidth} onValueChanged={handleMaxWidthChange} />
                  </IM.View>
                </SettingsRow>
              </SettingsRowContainer>
              <SettingsRowContainer title={i18n.t('LOTS')} spacing="vertical">
                <SettingsRow title={i18n.t('DOUBLE_DIAMETER')}>
                  <Switch value={lotSettings.enableDoubleDiameter} onValueChange={handleDoubleDiameterChange} />
                </SettingsRow>
                {lotSettings.enableDoubleDiameter && (
                  <SettingsRow title={i18n.t('DOUBLE_DIAMETER_VALUE')}>
                    <IM.View style={styles.label}>
                      <NumberInput disableFastInputs value={lotSettings.doubleDiameter} onValueChanged={handleDoubleDiameterValueChange} />
                    </IM.View>
                  </SettingsRow>
                )}
                <SettingsRow title={i18n.t('STANDING_UP')}>
                  <IM.View>
                    <TextInput style={{ color: isDark ? '#fff' : '#000' }} value={lotSettings.standingUp} onChangeText={handleStandingUpChange} />
                  </IM.View>
                </SettingsRow>
                <SettingsRow title={i18n.t('CALIBRATION')}>
                  <IM.View style={styles.label}>
                    <NumberInput disableFastInputs value={lotSettings.calibrate} onValueChanged={handleCalibrationChange} />
                  </IM.View>
                </SettingsRow>
              </SettingsRowContainer>
              <SettingsRowContainer title={i18n.t('INFO')} spacing="vertical">
                <SettingsRow title={i18n.t('VERSION')}>
                  <TextInput style={{ color: isDark ? '#fff' : '#000' }} value={DeviceInfo.getVersion()} />
                </SettingsRow>
                <SettingsRow title={i18n.t('BUILD_NUMBER')}>
                  <TextInput style={{ color: isDark ? '#fff' : '#000' }} value={DeviceInfo.getBuildNumber()} />
                </SettingsRow>
              </SettingsRowContainer>
            </>
          )}
        </IM.View>
      </ScrollView>
      <DevicesModal controller={devicesModalController} />
      <RoundingModal controller={roundingModalController} packageSettings={packageSettings} onPackageSettingsChange={handlePackageSettingsChange} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  dropdownRow: { zIndex: 1 },
  dropdownElevated: { zIndex: 2 },
  loading: {
    ...Platform.select({}),
    paddingRight: 10,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  label: { flex: 1, maxWidth: 100 },
})
