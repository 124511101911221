import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import PressableIcon from '../components/PressableIcon'
import { useBluetooth } from '../contexts/BluetoothContext'
import { ThemeColorExpanded } from '../types'

interface BaseHeaderProps {
  title: string
  rightComponent?: React.ReactNode
}

const BaseHeader = ({ title, rightComponent }: BaseHeaderProps) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isConnected } = useBluetooth()
  const bluetoothColor = isConnected ? theme.header.main.text.primary : theme.general.error

  return (
    <View style={styles.container}>
      <View style={styles.leftContent}>
        <IM.Text style={[styles.contentText, styles.title]}>{title}</IM.Text>
      </View>
      <View style={styles.rightContent}>
        {Platform.OS !== 'web' && (
          <View style={styles.bluetoothIconContainer}>
            <PressableIcon icon={['fal', 'bluetooth']} size={17} color={bluetoothColor} />
          </View>
        )}
        {rightComponent && <View style={styles.rightComponent}>{rightComponent}</View>}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  leftContent: {
    flex: 1,
    paddingHorizontal: Platform.OS === 'web' ? 0 : 16,
  },
  rightContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentText: {
    color: IMStyle.palette.white,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
  bluetoothIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
  },
  rightComponent: {
    marginLeft: 'auto',
    alignItems: 'center',
  },
})

export default BaseHeader
