import { IM, ModalController } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { PackageCreationContextProvider } from '../contexts/PackageCreationContext'
import PackageCreateHeader from '../screens/PackageCreate/PackageCreateHeader'
import PackageCreateView from '../screens/PackageCreate/PackageCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type PackageCreateModalProps = {
  controller: ModalController<PackageCreateModalControllerData>
  onCreateCallback: (id: string) => void
}

export type PackageCreateModalControllerData = {
  packageId?: string
}

export default function PackageCreateModal({ controller, onCreateCallback }: PackageCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  const [isShown, setIsShown] = useState(false)

  const handleCreate = (id: string) => {
    onCreateCallback(id)
    controller.close()
  }

  const handleClose = () => {
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <PackageCreationContextProvider>
        <Screen>
          <Screen.Header goBack={handleClose} backHandlerCallback={() => controller.close}>
            <PackageCreateHeader />
          </Screen.Header>
          <Screen.Content>
            {isShown && (
              <FormProvider>
                <PackageCreateView editId={controller.data?.packageId} onCreate={handleCreate} />
              </FormProvider>
            )}
            {!isShown && <IM.LoadingSpinner isVisible={true} />}
          </Screen.Content>
        </Screen>
      </PackageCreationContextProvider>
    </BaseServiceModal>
  )
}
