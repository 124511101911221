import { IM, IMLayout, IMStyle, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { Animated, NativeScrollEvent, NativeSyntheticEvent, StyleSheet, TouchableOpacity, View } from 'react-native'
import SwipeableFlatList from 'rn-gesture-swipeable-flatlist'

import AnimatedButton, { AnimatedButtonHideEvent } from '../../components/AnimatedButton'
import PackageCard from '../../components/cards/PackageCard'
import TextWithIcon from '../../components/TextWithIcon'
import { ADD_PACKAGE_BUTTON_ID } from '../../constants/ButtonIds'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { useData } from '../../contexts/DataContext'
import { usePackageFilterContext } from '../../contexts/PackageFilterContext'
import { usePackages } from '../../hooks/usePackages'
import PackageCreateModal, { PackageCreateModalControllerData } from '../../modals/PackageCreateModal'
import { ThemeColorExpanded } from '../../types'
import { Package } from '../../types/types'

const buttonAnimationValue = new Animated.Value(0)
export default function PackagesView() {
  const rootNavigation = useNavigation()
  const { packages } = useData()
  const { showOnlyOpen, searchString } = usePackageFilterContext()
  const filteredPackages = useMemo(() => {
    let result = packages
    if (showOnlyOpen) {
      result = result.filter(pkg => !pkg.closed)
    }
    if (searchString !== '') {
      result = result.filter(pkg => pkg.name.toLowerCase().includes(searchString.toLowerCase()))
    }
    return result
  }, [packages, showOnlyOpen, searchString])
  const { deletePackage } = usePackages()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const [listKey, setListKey] = useState(0)
  const packageCreateModal = useModalController<PackageCreateModalControllerData>()
  const { emit } = useEvent<AnimatedButtonHideEvent>({ key: EVENT_KEYS.ANIMATED_BUTTON_SHOULD_HIDE })

  const editItem = (item: Package) => {
    packageCreateModal.show({ packageId: item.tmpId })
  }

  const handleDeletePress = (item: Package) => {
    deleteItem(item)
  }

  const deleteItem = (item: Package) => {
    deletePackage(item.tmpId)
    setListKey(prevKey => prevKey + 1)
  }

  const handleCreate = (id: string) => {
    rootNavigation.navigate('PackagesMeasure', { packageTmpId: id })
  }

  const onCardPress = (pkg: Package) => {
    if (!pkg.closed) {
      rootNavigation.navigate('PackagesMeasure', { packageTmpId: pkg.tmpId })
    }
  }

  const renderPackageCard = ({ item }: { item: Package }) => (
    <View style={styles.packageCardContainer}>
      <PackageCard pkg={item} onPress={() => onCardPress(item)} />
    </View>
  )

  const renderRightActions = useCallback(
    (item: Package) => (
      <IM.View style={styles.actionContainer}>
        <TouchableOpacity onPress={() => editItem(item)} style={[styles.action, styles.leftAction, { backgroundColor: theme.primary }]}>
          <TextWithIcon alignIcon="top" icon={['fal', 'pen']} iconSize={22}>
            {i18n.t('EDIT')}
          </TextWithIcon>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleDeletePress(item)} style={[styles.action, styles.rightAction, { backgroundColor: theme.error }]}>
          <TextWithIcon alignIcon="top" icon={['fal', 'trash']} iconSize={22}>
            {i18n.t('DELETE')}
          </TextWithIcon>
        </TouchableOpacity>
      </IM.View>
    ),
    [deleteItem, editItem]
  )

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    const contentOffset = event.nativeEvent.contentOffset.y
    emit({ id: ADD_PACKAGE_BUTTON_ID, hide: contentOffset > 0 })
  }

  return (
    <>
      <SwipeableFlatList
        key={listKey}
        data={filteredPackages}
        renderItem={renderPackageCard}
        style={styles.packageList}
        renderRightActions={renderRightActions}
        onScroll={onScroll}
      />
      <PackageCreateModal controller={packageCreateModal} onCreateCallback={(id: string) => handleCreate(id)} />
      <AnimatedButton
        style={styles.button}
        id={ADD_PACKAGE_BUTTON_ID}
        value={buttonAnimationValue}
        icon={['fal', 'plus']}
        iconSize={50}
        onPress={() => packageCreateModal.show({})}
      />
    </>
  )
}

const styles = StyleSheet.create({
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  packageList: {
    width: '100%',
    padding: IMLayout.horizontalMargin,
  },
  actionContainer: {
    flexDirection: 'row',
    marginBottom: 10,
    marginRight: 5,
  },
  action: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: 75,
    height: '100%',
  },
  leftAction: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginTop: 5,
  },
  rightAction: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: 5,
  },
  packageCardContainer: {
    overflow: 'hidden',
  },
})
