import { IM, ModalController } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { LotCreationContextProvider } from '../contexts/LotCreationContext'
import LotCreateHeader from '../screens/LotCreate/LotCreateHeader'
import LotCreateView from '../screens/LotCreate/LotCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type LotCreateModalProps = {
  controller: ModalController<LotCreateModalControllerData>
  onCreateCallback: (id: string) => void
}

export type LotCreateModalControllerData = {
  lotId?: string
}

export default function LotCreateModal({ controller, onCreateCallback }: LotCreateModalProps) {
  const ref = createRef<ModalScreenRef>()
  const [isShown, setIsShown] = useState(false)

  const handleCreate = (id: string) => {
    onCreateCallback(id)
    controller.close()
  }

  const handleClose = () => {
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <LotCreationContextProvider>
        <Screen>
          <Screen.Header goBack={handleClose} backHandlerCallback={() => controller.close}>
            <LotCreateHeader />
          </Screen.Header>
          <Screen.Content>
            <FormProvider>
              {isShown && (
                <FormProvider>
                  <LotCreateView editId={controller.data?.lotId} onCreate={handleCreate} />
                </FormProvider>
              )}
              {!isShown && <IM.LoadingSpinner isVisible={true} />}
            </FormProvider>
          </Screen.Content>
        </Screen>
      </LotCreationContextProvider>
    </BaseServiceModal>
  )
}
