import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Keyboard, ScrollView, StyleSheet, TextStyle } from 'react-native'

import CONSTANTS from '../../../constants/constants'
import useObjectUtils from '../../../hooks/useObjectUtils'
import ScreenSelectModal, { ScreenSelectModalProps } from '../../../modals/ScreenSelectModal'
import { FiniteLoadList, InfiniteLoadList } from '../../../types/types'
import BaseTextInputTitle from '../../baseTextInput/BaseTextInputTitle'
import SelectTextInput from '../../SelectTextInput'
import SkeletonText from '../../skeleton/SkeletonText'
import Tag from '../../Tag'
import Text from '../../Text'
import { TextInputProps } from '../../TextInput'
import useOnLayout from '../../useOnLayout'

export type SelectInputProps<T> = (FiniteLoadList | InfiniteLoadList) &
  Omit<ScreenSelectModalProps<T>, 'selected'> & {
    id: keyof T | (keyof T)[]
    value?: T
    title?: string
    editable?: boolean
    spacing?: SpacingProps
    required?: boolean
    error?: boolean
    titleFontWeight?: TextStyle['fontWeight']
    disableFastInput?: boolean
    fastInputEntries?: number
    disableFastInputScrollView?: boolean
    showNoneButton?: boolean
    renderSelectedString: string | ((item: T) => string)
    hideNoSelectionItem?: boolean
    disabledInfo?: string | false
    autoSelectIfUnique?: boolean
    subTitle?: string
    showReduction?: boolean
    onSave?: (inputValue: string) => void
  } & Pick<TextInputProps, 'disableBorderRadius' | 'placeholder' | 'multiline'>

const MARGIN_SHADOW = 4

export default function SelectInput<T extends object>({
  id,
  value,
  title,
  spacing,
  error,
  titleFontWeight,
  editable = true,
  required,
  disableFastInput = false,
  disableFastInputScrollView,
  data,
  fastInputEntries,
  showNoneButton = false,
  hideNoSelectionItem,
  disabledInfo,
  disableBorderRadius,
  placeholder,
  multiline,
  autoSelectIfUnique,
  subTitle,
  showReduction,
  onSave,
  ...other
}: SelectInputProps<T>) {
  const { onChange, renderSelectedString, onSearchChange } = other

  const { i18n } = useLanguage()
  const { onLayout, layout } = useOnLayout()
  const { colorScheme } = useTheme()
  const noData = !other.loading && !data?.length
  const [contentWidth, setContentWidth] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const flexWrap = disableFastInputScrollView ? 'wrap' : 'nowrap'
  const searching = useRef(false)
  const objectUtils = useObjectUtils(id)

  const showFastInput =
    !disableFastInput &&
    data.length <= (fastInputEntries ?? CONSTANTS.maxDataLengthForFastInput) &&
    data.length > 0 &&
    !searching.current &&
    other.loading === false

  const handlePress = (el: T) => {
    !required && objectUtils.compare(value, el) ? (showNoneButton ? undefined : onChange(undefined)) : onChange(el)
  }

  useEffect(() => {
    if (!required || data.length !== 1 || value || searching.current || !autoSelectIfUnique) return
    onChange(data[0])
  }, [data, required])

  const handleSearchChange = (val: string) => {
    searching.current = val !== ''
    onSearchChange?.(val)
  }

  const placeHolder = useMemo(() => {
    if (noData) return other.noDataMessage
    if (other.loading === 'reloading') return i18n.t('LOADING_PLACEHOLDER')
    if (value) return i18n.t('MISSING_DESCRIPTION')
    if (placeholder) return placeholder
    return i18n.t('NO_SELECTION')
  }, [value, noData, other.loading, placeholder, other.noDataMessage])

  const selectTextInputText = useMemo(() => {
    if (!renderSelectedString) return ''
    if (typeof renderSelectedString === 'string') return renderSelectedString
    if (value) return renderSelectedString(value)
    return ''
  }, [value, renderSelectedString])

  return (
    <>
      {!showFastInput && (
        <>
          <SelectTextInput
            title={title}
            titleFontWeight={titleFontWeight}
            value={selectTextInputText}
            placeholder={placeHolder}
            onPress={() => {
              Keyboard.dismiss()
              setIsVisible(true)
            }}
            onReset={() => onChange(undefined)}
            onRefresh={other.refresh}
            required={required}
            editable={other.loading === false && editable && !noData}
            spacing={spacing}
            error={error}
            disabledInfo={editable ? undefined : disabledInfo}
            disableBorderRadius={disableBorderRadius}
            noData={noData}
            multiline={multiline}
            details={subTitle}
          />
          <ScreenSelectModal
            data={data}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            showNoSelectionItem={!required && !hideNoSelectionItem}
            selected={value}
            {...other}
            onSearchChange={handleSearchChange}
            onSave={onSave}
            showReduction={showReduction}
          />
        </>
      )}
      {showFastInput && (
        <IM.View spacing={spacing} style={{ marginBottom: -MARGIN_SHADOW, marginLeft: -MARGIN_SHADOW }} onLayout={onLayout}>
          {title && (
            <IM.View style={{ marginLeft: MARGIN_SHADOW }}>
              <BaseTextInputTitle title={title} details={subTitle} fontWeight={titleFontWeight} required={required} />
            </IM.View>
          )}
          <IM.View style={!disableFastInputScrollView && styles.fastInputView}>
            {other.loading !== 'reloading' && (
              <ScrollView
                indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
                horizontal={!disableFastInputScrollView}
                scrollEnabled={!disableFastInputScrollView && !!layout && contentWidth >= layout.width}
                contentContainerStyle={[IMLayout.flex.row, { paddingBottom: MARGIN_SHADOW, paddingLeft: MARGIN_SHADOW, flexWrap }]}
                onContentSizeChange={w => !disableFastInputScrollView && setContentWidth(w)}>
                {value === undefined && !editable && <Text secondary>{i18n.t('NO_SELECTION')}</Text>}
                {data.map(el => {
                  if (!editable && !objectUtils.compare(el, value)) return <IM.View key={objectUtils.createId(el)} />

                  return (
                    <Tag
                      key={objectUtils.createId(el)}
                      name={typeof renderSelectedString === 'string' ? renderSelectedString : renderSelectedString(el)}
                      active={objectUtils.compare(el, value)}
                      id={objectUtils.createId(el)}
                      onPress={() => handlePress(el)}
                      style={styles.tag}
                      disabled={!editable}
                    />
                  )
                })}
              </ScrollView>
            )}
            {other.loading === 'reloading' && <SkeletonText width="99%" height={30} style={[styles.loading, { marginLeft: MARGIN_SHADOW }]} />}
          </IM.View>
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fastInputView: {
    height: 48, //empirical value. set to avoid "jumping" of components
  },
  loading: { paddingVertical: 4, paddingBottom: 13 },
  tag: { marginVertical: 4, marginHorizontal: 3 },
})
