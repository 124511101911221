import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Measurer } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import MeasurerCard from '../cards/MeasurerCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Measurer | undefined) => void
  required?: boolean
  selectedMeasurerId?: number
}

export default function MeasurerSelector({ onChange, selectedMeasurerId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { measurers, organizationId, setMeasurers } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(measurers, search, ['measurerName', 'measurerId', 'measurerCode'])

  const render = ({ item }: SectionListRenderItemInfo<Measurer, ListSection<Measurer>>, onPress?: () => void) => {
    return <MeasurerCard measurer={item} onPress={onPress} selected={item.measurerId === selectedMeasurerId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Measurer | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveMeasurer(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveMeasurer = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postMeasurer({ measurerName: inputValue, organizationId: organizationId })
      const res = await api.getMeasurers({ organizationId: organizationId })
      setMeasurers(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedMeasure = useMemo(() => measurers?.find(elem => elem.measurerId === selectedMeasurerId), [measurers, selectedMeasurerId])

  return (
    <SelectInput
      id={'measurerId'}
      data={filteredData}
      value={selectedMeasure}
      loading={false}
      refresh={undefined}
      title={i18n.t('MEASURER')}
      screenTitle={i18n.t('MEASURER')}
      noDataMessage={i18n.t('NO_MEASURER')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.measurerName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      {...props}
    />
  )
}
