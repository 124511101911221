import { IM } from '@infominds/react-native-components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { PanResponder, StyleSheet } from 'react-native'

import { Customer, Grade, Quality, Typology, Wood } from '../../apis/apiResponseTypes'
import { Screen } from '../../components/screen/Screen'
import { useData, useDataByPackage } from '../../contexts/DataContext'
import { usePackage } from '../../hooks/usePackage'
import { RootStackParamList } from '../../navigation/types'
import PackageMeasureHeader from './PackageMeasureHeader'
import PackageMeasureView from './PackageMeasureView'

type PackagesMeasureRouteProp = RouteProp<RootStackParamList, 'PackagesMeasure'>

export default function PackageMeasureScreen() {
  const route = useRoute<PackagesMeasureRouteProp>()
  const packageTmpId = route.params.packageTmpId

  const { pkg } = usePackage(packageTmpId)
  const { wood, typology, grade, quality, customer } = useDataByPackage(pkg)
  const [pkgLoaded, setPkgLoaded] = useState(false)
  const [swipeMode, setSwipeMode] = useState(false)
  const { packages } = useData()
  const navigation = useNavigation()

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderRelease: (e, gestureState) => {
      const { dx } = gestureState

      if (dx > 50) {
        onPreviousPackage()
      } else if (dx < -50) {
        onNextPackage()
      }
    },
  })

  useEffect(() => {
    if (pkg) {
      setPkgLoaded(true)
    }
  }, [pkg])

  const handleSwitchMode = () => {
    setSwipeMode(!swipeMode)
  }

  const onPreviousPackage = () => {
    const currentIndex = packages.findIndex(p => p.tmpId === packageTmpId)
    const previousIndex = (currentIndex - 1 + packages.length) % packages.length
    const previousPackageTmpId = packages[previousIndex].tmpId
    navigation.navigate('PackagesMeasure', { packageTmpId: previousPackageTmpId })
  }

  const onNextPackage = () => {
    const currentIndex = packages.findIndex(p => p.tmpId === packageTmpId)
    const nextIndex = (currentIndex + 1 + packages.length) % packages.length
    const nextIndexTmpId = packages[nextIndex].tmpId
    navigation.navigate('PackagesMeasure', { packageTmpId: nextIndexTmpId })
  }

  return (
    <IM.View style={styles.container} {...panResponder.panHandlers}>
      {!pkgLoaded && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      {pkgLoaded && (
        <>
          <Screen forceDetailOpen>
            <Screen.Header goBack>
              <PackageMeasureHeader packageId={packageTmpId} packageType={pkg?.packageType} onSwitchMode={handleSwitchMode} />
            </Screen.Header>
            <Screen.DetailHeader minHeight={50}>
              <TestDetailHeader customer={customer} wood={wood} typology={typology} grade={grade} quality={quality} packageTmpId={packageTmpId} />
            </Screen.DetailHeader>
            <Screen.Content>
              <PackageMeasureView packageTmpId={packageTmpId} swipeMode={swipeMode} />
            </Screen.Content>
          </Screen>
        </>
      )}
    </IM.View>
  )
}

interface TestDetailHeaderProps {
  customer?: Customer
  wood?: Wood
  typology?: Typology
  grade?: Grade
  quality?: Quality
  packageTmpId: string
}

const iconSize = 20

function TestDetailHeader({ customer, wood, typology, grade, quality, packageTmpId }: TestDetailHeaderProps) {
  const { packages } = useData()
  const navigation = useNavigation()

  const onPreviousPackage = () => {
    const currentIndex = packages.findIndex(p => p.tmpId === packageTmpId)
    const previousIndex = (currentIndex - 1 + packages.length) % packages.length
    const previousPackageTmpId = packages[previousIndex].tmpId
    navigation.navigate('PackagesMeasure', { packageTmpId: previousPackageTmpId })
  }

  const onNextPackage = () => {
    const currentIndex = packages.findIndex(p => p.tmpId === packageTmpId)
    const previousIndex = (currentIndex + 1 + packages.length) % packages.length
    const previousPackageTmpId = packages[previousIndex].tmpId
    navigation.navigate('PackagesMeasure', { packageTmpId: previousPackageTmpId })
  }
  return (
    <IM.View style={styles.centerContainer}>
      <IM.PressableIcon size={iconSize} color={'#D9D9D9'} icon={['fal', 'chevron-left']} onPress={onPreviousPackage} />
      <IM.Text style={styles.text}>
        {(customer?.customerName ?? '') +
          '/' +
          (wood?.woodName ?? '') +
          '/' +
          (typology?.typologyName ?? '') +
          '/' +
          (grade?.gradeName ?? '') +
          '/' +
          (quality?.qualityName ?? '')}
      </IM.Text>
      <IM.PressableIcon size={iconSize} color={'#D9D9D9'} icon={['fal', 'chevron-right']} onPress={onNextPackage} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  text: {
    color: 'white',
    fontSize: 14,
  },
  centerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
