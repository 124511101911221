import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { ActivityIndicator, FlatList, StyleSheet, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import DeviceCard from '../../components/cards/DeviceCard'
import { useBluetooth } from '../../contexts/BluetoothContext'
import { useData } from '../../contexts/DataContext'
import { BluetoothDevice, ThemeColorExpanded } from '../../types'

export default function DevicesView({ onContinueWithoutDevice, onCloseModal }: { onContinueWithoutDevice: () => void; onCloseModal: () => void }) {
  const { i18n } = useLanguage()
  const { scannedDevices, startScan, isScanning } = useBluetooth()
  const { configuredDevices } = useData()
  const { theme } = useTheme<ThemeColorExpanded>()

  useEffect(() => {
    startScan()
  }, [])

  const handleDevicePress = () => {
    onCloseModal()
  }

  const renderDevice = ({ item }: { item: BluetoothDevice }) => {
    configuredDevices.forEach(device => {
      if (item.name && item.name.toLowerCase().includes(device.name.toLowerCase())) {
        item.deviceType = device.type
        item.manufacturer = device.manufacturer
      }
    })

    return <DeviceCard device={item} onPress={handleDevicePress} />
  }

  const handleScanAgain = () => {
    startScan()
  }

  return (
    <SafeAreaView style={IMLayout.flex.f1}>
      <IM.View spacing={'all'} spacingType="margin" style={styles.container}>
        <View style={styles.listAndButtonContainer}>
          {scannedDevices.length > 0 ? (
            <FlatList data={scannedDevices} renderItem={renderDevice} keyExtractor={item => item.id} style={styles.flatList} />
          ) : (
            <IM.Text>{i18n.t('NO_DEVICE_FOUND')}</IM.Text>
          )}
          <TouchableOpacity style={styles.scanButton}>
            <IM.Button title={i18n.t('SCAN_AGAIN')} onPress={handleScanAgain} type={'neutral'} disabled={isScanning} />
          </TouchableOpacity>
        </View>
        {isScanning && (
          <IM.View style={styles.spinnerContainer}>
            <ActivityIndicator size="large" color={theme.primary} style={styles.spinner} />
          </IM.View>
        )}
      </IM.View>

      <TouchableOpacity style={styles.clickableLabel} onPress={onContinueWithoutDevice}>
        <IM.Text style={styles.clickableLabelText}>{i18n.t('CONTINUE_WITHOUT_DEVICE') + ' >'}</IM.Text>
      </TouchableOpacity>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  listAndButtonContainer: {
    flexShrink: 1,
  },
  flatList: {
    maxHeight: '70%',
  },
  clickableLabel: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  scanButton: {
    marginTop: 10,
  },
  clickableLabelText: {
    textDecorationLine: 'underline',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  spinner: {
    marginVertical: 20,
  },
})
