import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import SearchField from '../SearchField'
import Paging from './components/paging/Paging'
import TABLE_CONSTANTS from './constants/TableConstants'
import Table from './Table'
import { TableHeaderData } from './types/types'
import TableUtils from './utils/TableUtils'

export type TableWrapperProps<T> = {
  tableTitle?: string
  headerData: TableHeaderData<T>[]
  tableData: T[]
  tableContainerSpacing?: SpacingProps
  tableContainerStyle?: StyleProp<ViewStyle>
  hasEditAndDelete?: boolean
  onDeletePress?: (item: T) => void
  onEditPress?: (item: T) => void
  showEmptyRow?: boolean
  showNewItemRow?: boolean
  onNewItemPress?: () => void
  maxTableHeight?: number
  tableStyle?: StyleProp<ViewStyle>
  widthArray?: number[]
  flexArray?: number[]
  hasPaging?: boolean
  rowsPerPage?: number
  maxVisiblePages?: number
}

const DEFAULT_ROWS_PER_PAGE = 100

//TODO: add paging
export default function TableWrapper<T>({
  tableTitle,
  headerData,
  tableData,
  tableContainerSpacing,
  tableContainerStyle,
  tableStyle,
  maxTableHeight,
  showEmptyRow = true,
  flexArray,
  widthArray,
  showNewItemRow,
  onNewItemPress,
  hasEditAndDelete,
  onDeletePress,
  onEditPress,
  hasPaging,
  rowsPerPage = TABLE_CONSTANTS.defaultRowsPerPage,
  maxVisiblePages = TABLE_CONSTANTS.maxVisiblePages,
}: TableWrapperProps<T>) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentSkip, setCurrentSkip] = useState<number>(0)

  const filteredTableData: T[] = useMemo(() => {
    return TableUtils<T>().filterData(tableData, searchValue)
  }, [tableData, searchValue])

  useEffect(() => {
    if (!hasPaging) return

    const calcTotalPages = Math.ceil(filteredTableData.length / rowsPerPage)
    setTotalPages(calcTotalPages)
  }, [hasPaging, filteredTableData, rowsPerPage])

  function handleTableSearch(text: string | undefined) {
    setSearchValue(text)
    setCurrentPage(1)
    setCurrentSkip(0)
  }

  function handlePageChange(page: number) {
    setCurrentPage(page)
    setCurrentSkip((page - 1) * rowsPerPage)
  }

  return (
    <IM.View spacing={tableContainerSpacing} style={tableContainerStyle}>
      <IM.View style={styles.infoTitle}>
        <IM.Text>{tableTitle ?? ''}</IM.Text>
        <SearchField value={searchValue} handleSearch={handleTableSearch} />
      </IM.View>
      <Table
        style={tableStyle}
        headerData={headerData}
        tableData={filteredTableData}
        showNewItemRow={showNewItemRow}
        showEmptyRow={showEmptyRow}
        onNewItemPress={onNewItemPress}
        hasEditAndDelete={hasEditAndDelete}
        onEditPress={onEditPress}
        onDeletePress={onDeletePress}
        flexArray={flexArray}
        widthArray={widthArray}
        maxHeight={maxTableHeight}
        paging={hasPaging ? { skip: currentSkip, take: currentSkip + rowsPerPage } : undefined}
      />
      {hasPaging && (
        <Paging currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} maxVisiblePages={maxVisiblePages} />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  infoTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
