import { IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import Switch from '../../components/Switch'
import TextInput from '../../components/TextInput'
import { usePackageFilterContext } from '../../contexts/PackageFilterContext'

export const PackageFilterHeader = () => {
  const { i18n } = useLanguage()
  const { searchString, showOnlyOpen, setSearchString, setShowOnlyOpen } = usePackageFilterContext()

  return (
    <IM.View style={styles.container}>
      <IM.View style={styles.inputContainer}>
        <TextInput placeholder={i18n.t('SEARCH')} value={searchString || ''} onChangeText={value => setSearchString(value)} style={styles.input} />
      </IM.View>
      <IM.View style={styles.settingsRow} spacing="horizontal">
        <IM.Text style={styles.text}>{i18n.t('SHOW_ONLY_OPEN')}</IM.Text>
        <Switch onValueChange={() => setShowOnlyOpen(!showOnlyOpen)} value={showOnlyOpen || false} style={styles.switch} />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  input: {
    height: 40,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    backgroundColor: '#fff',
  },
  settingsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    height: 40,
  },
  switch: {
    alignSelf: 'center',
  },
  text: {
    color: 'white',
  },
})

export default PackageFilterHeader
