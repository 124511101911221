import { Language } from '@infominds/react-native-components'
import { DurationUnitsObjectType } from 'dayjs/plugin/duration'
import { Dispatch, SetStateAction } from 'react'

import { translation } from '../assets/languages/i18next'
import { LotSettings, PackagesSettings } from '../types'

export type i18nKey = keyof typeof translation.en
export type DeviceData = {
  DeviceId: string
  DeviceName: string
  AppVersion: string
  OsVersion: string
  DeviceInfo: string
}

export type CustomQRCodeProcessorFunction = (code: string) => LoginCustomQRCodeProcessorResult | null
export type Locale = 'en' | 'de' | 'it'
export type ApiType = '.net' | 'delphi' | 'identityServer'
export type LicenseState = 'None' | 'Enable' | 'Inactive'
export type RegisteredDeviceState = 'None' | 'Enable' | 'Inactive'
export type ExtendedResponse<T> = { data: T; updatedAt: number }

export type Form = {
  username?: string
  password?: string
}

export type LicenseType = 'PackageAndLot' | 'Complete'

export type ErrorResponseDelphi = {
  message: string
  Code: number
}

export type ApiError = {
  Message: string
  code: string
}

export type DropdownItems<T> = { label: string; value: T }

export const SupportedLanguages: DropdownItems<Language>[] = [
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
  { label: 'English', value: 'en' },
]

export enum LoginErrorType {
  generic,
  iosApiToken,
  iosCompanyList,
  erpCompanyNotFoundOnIos,
  licenseValidation,
  registerDevice,
  login,
  appRegisterDeviceNoneState,
  licenseNotFound,
  licenseExpired,
  tooManyLicenses,
  microsoftCredentials,
  fetchCredential,
  microsoftMissingCredentials,
  userCancelledAuth,
  userClosedAuth,
  cacheCredentialUpdateToken,
  missingCredentials,
  serviceUrlNotFound,
  companyNotFoundOnToken,
}

export enum LoginWarnType {
  generic,
  userInactive,
}

export interface DateTimePickerProps {
  date: Date
  show: boolean
  mode: 'date' | 'time' | 'datetime'
  minimumDate?: Date
  maximumDate?: Date
  setDate: (newDate: Date) => void
  setShow: Dispatch<SetStateAction<boolean>>
}

export type LoginCustomQRCodeProcessorResult = {
  tryLogin?: boolean
  licenseKey?: string
  username?: string
  password?: string
}

export type PackageUmValue = 'CMCMMM' | 'MMMMMM' | 'FTININ'
export type PackageUm = { value: PackageUmValue | undefined }

export const packageUmValues: PackageUm[] = [{ value: 'CMCMMM' }, { value: 'MMMMMM' }, { value: 'FTININ' }]

export type LoadingType = 'reloading' | 'hiddenLoading' | 'aborted' | 'init' | 'catched' | false

export type InferArrayType<T> = T extends Array<infer E> ? E : T

export type VersionsCheckFile = {
  minVersions: Record<string, string | undefined>
}

export type InAppUpdatesReturn = {
  updateAvailable: boolean | undefined
  prompt: () => void
  check: () => void
}

export type FiniteLoadList = {
  allDataLoaded?: never
  onLoadMore?: never
}

export type InfiniteLoadList = {
  allDataLoaded: boolean
  onLoadMore: () => void
}

export type ListSection<T> = {
  title?: string
  data: T[]
}

export type VersionCheckEndValue = { minApiVersion: string; apiVersion: string; message: string; title: string; checkResult?: string }

export type VersionCheckContextType = {
  versionsFile?: VersionsCheckFile
  /** Callback used there is an api version mismatch */
  onVersionCheckEnd?: (value: VersionCheckEndValue) => void
}

export type DisableSilentLoginType = boolean | 'ask'

export type DemoData = { license: string; username: string; password: string }

export type AuthConfig = {
  /**
   * Module code as it appears on IOS
   */
  moduleCode: string
  /**
   * Project code as it appears on IOS
   */
  projectCode: string
  /**
   * This is used to identify the type of backend the app speaks with
   * This options has no meaning the a 'customLoginFunction' is passed
   */
  apiType: ApiType

  /**
   * AAD on web need to set a different redirect url when the web app is a singlePageApp.
   * @web only
   */
  isSinglePageApp?: boolean
  keepLicenseOnLogout?: boolean
  /**
   * This disable the offline features
   * @default false
   */
  disableOfflineMode?: boolean
  /**
   * When the app refreshes, the user must login again
   * @default false
   */
  disableSilentLogin?: DisableSilentLoginType
  /**
   * Whether the app manages AAD login or not
   * @default false
   */
  aad?: boolean
  /**
   * This disables the automatic check for newer version on stores
   * @default false
   */
  disableInAppUpdateCheck?: boolean
  /**
   * Duration from last fetch until the offline cache is used when device is offline or server is down
   * @default {days: 7}
   */
  cacheInvalidation?: DurationUnitsObjectType
  /**
   * Use a custom login function. This is used only when the license login type is set to 'Default' on IOS
   */
  /**
   * Enable debug logs
   * @default false
   */
  debug?: boolean
  /** The session key identify the user, licenseId and company.
   *  It can be used to save user preference on multi user-apps. The suffix is a string that can be place at the end of the session id */
  sessionKeySuffix?: string
}

export type PackageType = { value: string; label: i18nKey }

export type LotType = { value: string; label: i18nKey }

export type LicenseSelectType = { value: string; label: i18nKey }

export const lotTypeValues: LotType[] = [
  { value: 'Diameter1', label: 'DIAMETER_1' },
  { value: 'Diameter2', label: 'DIAMETER_2' },
  { value: 'Diameter3', label: 'DIAMETER_3' },
  { value: 'Diameter4', label: 'DIAMETER_4' },
  { value: 'Forest', label: 'FOREST' },
  { value: 'Hardwood', label: 'HARDWOOD' },
  { value: 'StandingUp', label: 'STANDING_UP' },
  { value: 'StandingUpHeight', label: 'STANDING_UP_HEIGHT' },
  { value: 'QGis', label: 'QGIS' },
]

export const packageTypeValues: PackageType[] = [
  { value: 'TableToTable', label: 'TABLE_TO_TABLE' },
  { value: 'Prismatic', label: 'PRISMATIC' },
  { value: 'Trasversal', label: 'TRASVERSAL' },
  { value: 'AutomaticLength', label: 'AUTOMATIC_LENGTH' },
  { value: 'FirstLengths', label: 'FIRST_LENGTHS' },
  { value: 'ChoppingBoard', label: 'CHOPPING_BOARD' },
  { value: 'Parquet', label: 'PARQUET' },
  { value: 'Weight', label: 'WEIGHT' },
]

export const licenseSelectTypeValues: LicenseSelectType[] = [
  { value: 'PackageAndLot', label: 'PACKAGE_AND_LOT' },
  { value: 'Complete', label: 'COMPLETE' },
]

export type Package = {
  customerId: number
  name: string
  boards: Board[]
  thickness: number
  date: string
  typologyId: number
  qualityId: number
  woodId: number
  gradeId: number
  measurerId: number
  sawmillId: number
  commission: number
  notes: string
  packageType: PackageType
  ums: PackageUm
  closed: boolean
  tmpId: string
  settings: PackagesSettings
  maxLength?: number
  volume: number
}

export type Lot = {
  customerId: number
  name: string
  date: string
  woodId: number
  measurerId: number
  originId: number
  qualityId: number
  commission: number
  sawmillId: number
  notes: string
  lotType: LotType
  tmpId: string
  closed: boolean
  settings: LotSettings
  trunks: Trunk[]
  volume: number
}

export type Board = {
  boardId?: number
  packageId?: number
  boardNumber: number
  boardHeight: number
  boardLength: number
  boardWidth: number
  boardQuantity: number
  widthUm: string
  lengthUm: string
  addDate: string
  orderItemId: number
}

export type Trunk = {
  trunkId?: number
  lotId?: number
  trunkNumber: number
  qualityId: number
  woodId: number
  addDate: string
  length: number
  diameter_1: number
  diameter_2: number
  diameter_3: number
  diameter_4: number
  reduction: number
  bonusDiameter: number
  bonusLength: number
  bonusWood: number
  latitude?: number
  longitude?: number
}
